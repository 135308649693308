@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

.About {
  height: 100%;
  background-color: var(--background-color);
  font-family: "Poppins", sans-serif;
  font-weight: 300;
}

/* Container for the scrollable divs */
.container {
  height: 100vh;
  scroll-snap-type: y mandatory; /* Snapping along the vertical axis */
  overflow-y: scroll; /* Enables vertical scrolling */
  scroll-behavior: smooth; /* Smooth scrolling between sections */
  scrollbar-width: none; /* Hides the scrollbar (optional for styling) */
}

.container > div {
  scroll-snap-align: start; /* Snaps to the top of the viewport */
  scroll-snap-stop: always; /* Ensures the div is fully on-screen before moving to the next */
}

/* Optional styling to remove the scrollbar for Webkit browsers */
.container::-webkit-scrollbar {
  display: none;
}

:root {
  --background-color: rgb(13, 0, 66);
  --foreground-color: #8680a1;
  --primary-text-color: white;
  --secondary-text-color: rgb(253, 95, 13);
  --toggle-bg: rgb(13, 0, 66);
  --toggle-fg: white;
}

[data-theme="light"] {
  --background-color: white;
  --foreground-color: rgb(13, 0, 66);
  --primary-text-color: rgb(13, 0, 66);
  --secondary-text-color: rgb(253, 95, 13);
  --toggle-bg: white;
  --toggle-fg: rgb(13, 0, 66);
}