.TitleWordList {
  text-align: center;
  height: 15vh;
}

.TitleWordList p {
  margin: 0;
  height: 5vh;
}

.TitleWordListComponent::-webkit-scrollbar {
  display: none;
}

.TitleWordListComponent {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

.scroll-item {
  padding: 1vw;
  color: var(--primary-text-color);
  transition: color 0.3s;
}

.scroll-item.active {
  color: var(--secondary-text-color);
  padding: 1vw;
}

@media (max-width: 768px) {
  .TitleWordList p {
    margin: 0;
    height: 8vh;
  }
}