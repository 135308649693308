/* Sticky container for scroll behavior */
.sc3_container {
  height: 100vh;
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: center;
  position: sticky;
  top: 0;
  z-index: 10;
  overflow: visible;
  scroll-snap-align: start; /* Ensures snap at the start of the container */
  scroll-padding-top: 80px; /* Adjust to match the navbar height */
}

/* Title Alignment */
.sc3_title {
  color: var(--primary-text-color);
  font-size: 60px;
  font-weight: 500;
  text-align: center;
  width: 100%;
  opacity: 0;
  transform: translateY(20px);
  transition: opacity 0.5s ease, transform 0.5s ease, font-size 0.5s ease,
    margin-top 0.5s ease;
  margin-bottom: 0; /* Adjusted to prevent shifting during shrinking */
  display: flex;
  justify-content: center;
  align-items: center;
}

.sc3_title.show {
  opacity: 1;
  transform: translateY(0);
}

.sc3_title.shrink h2{
  font-size: 40px;
  font-weight: 500;
  margin-top: 0; /* Adjusted to avoid title moving down when shrinking */
}

/* Highlight for Orange Text in Title */
.sc3_highlight {
  color: var(--secondary-text-color);
  font-weight: 500;
}

/* Paragraph container width adjustment */
.sc3_paragraph {
  width: 49%;
  line-height: 1.7;
  color: var(--primary-text-color);
  text-align: center;
  opacity: 0;
  transform: translateY(20px);
  transition: opacity 0.5s ease, transform 0.5s ease;
  font-size: 18px;
  font-weight: 300;
  margin-top: 20px;
}

.sc3_paragraph--show {
  opacity: 1;
  transform: translateY(0);
}

/* Solution Grid Section */
.sc3_grid {
  display: flex;
  overflow-x: auto;
  gap: 20px;
  max-width: 90%;
  margin: 20px auto;
  padding: 20px;
  position: relative;
  top: -40px;
  scroll-snap-type: x mandatory;
  opacity: 0;
  transform: translateY(20px);
  transition: opacity 0.5s ease, transform 0.5s ease;
}

.sc3_grid--show {
  opacity: 1;
  transform: translateY(0);
}

/* Individual Solution Item Styling */
.sc3_item {
  flex: 0 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 120px;
  height: 120px;
  cursor: pointer;
  padding: 20px;
  border-radius: 15px;
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.2);
  background: white;
  transition: transform 0.3s ease, background 0.4s ease, box-shadow 0.4s ease;
}

.sc3_item:hover {
  transform: translateY(-10px);
  background: linear-gradient(
    145deg,
    rgba(0, 0, 139, 0.95),
    rgba(255, 85, 0, 0.15)
  );
}

/* Image Styling */
.sc3_item-img {
  width: 85%;
  height: auto;
  max-width: 95px;
  max-height: 95px;
  transition: transform 0.3s ease-in-out;
}

.sc3_item:hover .sc3_item-img {
  transform: scale(1.1);
}

/* Minimalist Scrollbar Styling */
.sc3_grid::-webkit-scrollbar {
  height: 4px;
}

.sc3_grid::-webkit-scrollbar-thumb {
  background: var(--secondary-text-color);
  border-radius: 10px;
}

/* Media queries for smaller screens */
@media (max-width: 768px) {
  .sc3_title {
    font-size: 40px;
    margin-top: 50px;
  }

  .sc3_paragraph {
    width: 60%;
    font-size: 16px;
  }

  .sc3_item {
    width: 105px;
    height: 105px;
  }
}

@media (max-width: 480px) {
  .sc3_title {
    color: white;
    font-size: 45px;
    font-weight: 500;
    text-align: center;
    width: 100%;
    opacity: 0;
    transform: translateY(20px);
    transition: opacity 0.5s ease, transform 0.5s ease, font-size 0.5s ease,
      margin-top 0.5s ease;
    margin-bottom: 0; /* Adjusted to prevent shifting during shrinking */
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .sc3_title.show {
    opacity: 1;
    transform: translateY(0);
  }

  .sc3_title.shrink h2{
    font-size: 22px;
    margin-top: 0; /* Adjusted to avoid title moving down when shrinking */
  }

  .sc3_paragraph {
    width: 80%;
    font-size: 12px;
  }

  .sc3_item {
    width: 85px;
    height: 85px;
  }
}
