  /* Basic form styling */
body {
    font-family: 'Poppins', sans-serif;
    font-weight: 300;
    background-color: var(--background-color);
    color: var(--primary-text-color);
    padding: 20px;
    /* margin: 0; */
    overflow-y: auto;
  }

  .split-sides {
    display: flex;
    flex-wrap: wrap;
    gap: 4%;
  }

  .left-section {
    width: 48%;
  }

  .right-section {
    width: 48%;
  }

  /* Container for the scrollable divs */
.contact-page-container {
    height: 100vh;
    scroll-snap-type: y none; /* Snapping along the vertical axis */
    overflow-y: scroll; /* Enables vertical scrolling */
    scroll-behavior: smooth; /* Smooth scrolling between sections */
    scrollbar-width: none; /* Hides the scrollbar (optional for styling) */
  }
  
  .contact-page-container > div {
    scroll-snap-align: start; /* Snaps to the top of the viewport */
    scroll-snap-stop: always; /* Ensures the div is fully on-screen before moving to the next */
  }
  
  /* Optional styling to remove the scrollbar for Webkit browsers */
  .contact-page-container::-webkit-scrollbar {
    display: none;
  }
  
  @media (max-width: 480px) {
    .contact-page-container {
      overflow-x: hidden;
    }
  }

  .contact-page {
    height: 100vh;
  }
  
  .contact-page form {
    background-color: rgba(255, 255, 255, 0);
    padding: 15px;
    border-radius: 8px;
    max-width: 600px;
    width: 60vw;
    margin: auto;
    margin-top: 100px;
    /* margin-bottom: 0; */
    font-family: 'Poppins', sans-serif;
    font-weight: 300;
    font-size: 14px; /* Make everything a bit smaller */
    color: var(--primary-text-color);
  }
  
  .contact-page h2 {
    text-align: center;
    color: var(--primary-text-color);
    font-weight: 500;
    font-size: max(35px,3vw);
    font-family: 'Poppins', sans-serif;
    margin-bottom: 15px;
  }
  
  .contact-page label {
    display: block;
    margin-bottom: 5px;
    font-family: 'Poppins', sans-serif;
    font-size: 18px;
    font-weight: 300;
  }
  
  
  .contact-page input[type="text"],
  .contact-page input[type="email"],
  .contact-page input[type="tel"],
  .contact-page input[type="number"],
  .contact-page input[type="date"],
  .contact-page select,
  .contact-page textarea {
    width: 100%;
    padding: 8px; /* Smaller padding for smaller input sizes */
    margin-bottom: 15px;
    border: 1px solid #ddd;
    border-radius: 10px;
    font-size: 14px;
    box-sizing: border-box;
    font-family: 'Poppins', sans-serif;
  }
  
  .contact-page textarea {
    resize: vertical;
  }
  
  .contact-page input:focus,
  .contact-page select:focus,
  .contact-page textarea:focus {
    outline: none;
    border-color: #007bff;
  }
  
  .contact-form-submit-all {
    --color: var(--primary-text-color);
    width: 20vw; 
    max-width: 200px;
    padding: 0.8em 2em; 
    background-color: transparent;
    border-radius: 30px;
    position: relative;
    overflow: hidden;
    cursor: pointer;
    transition: 0.5s;
    font-weight: 300;
    font-size: 0.75rem; 
    border: 1px solid;
    font-family: "Poppins", sans-serif;
    text-transform: uppercase;
    border-color: var(--secondary-text-color);
    color: var(--color);
    z-index: 1;
}

.contact-form-submit-all::before,
.contact-form-submit-all::after {
    content: '';
    display: block;
    width: 35px;
    height: 35px;
    transform: translate(-50%, -50%);
    position: absolute;
    border-radius: 50%;
    z-index: -1;
    background-color: var(--secondary-text-color);
    transition: 1s ease;
}

.contact-form-submit-all::before {
    top: -1em;
    left: -1em;
}

.contact-form-submit-all::after {
    left: calc(100% + 1em);
    top: calc(100% + 1em);
}

.contact-form-submit-all:hover::before,
.contact-form-submit-all:hover::after {
    height: 410px;
    width: 410px;
}

.contact-form-submit-all:hover {
    color: var(--primary-text-color);
}

.contact-form-submit-all:active {
    filter: brightness(.8);
}
  
  .contact-page p {
    text-align: center;
    margin-top: 10px;
    color: white;
  }
  
  .error {
    color: red;
  }

  .success-message {
    color: green;
    font-size: 1.2rem;
    margin-top: 10px;
  }
  
  .error-message {
    color: red;
    font-size: 0.875rem;
    margin-top: 5px;
  }
  
  
  /* Styling for the Continue button */
  .continue-button {
    text-align: center;
    margin: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

.continue-button button {
    --color: var(--primary-text-color);
    width: max(120px,20vw); 
    max-width: 200px;
    padding: 0.8em 2em; 
    background-color: transparent;
    border-radius: 30px;
    position: relative;
    overflow: hidden;
    cursor: pointer;
    transition: 0.5s;
    font-weight: 300;
    font-size: 0.75rem; 
    border: 1px solid;
    font-family: "Poppins", sans-serif;
    text-transform: uppercase;
    border-color: var(--secondary-text-color);
    color: var(--color);
    z-index: 1;
}

.continue-button button::before,
.continue-button button::after {
    content: '';
    display: block;
    width: 35px;
    height: 35px;
    transform: translate(-50%, -50%);
    position: absolute;
    border-radius: 50%;
    z-index: -1;
    background-color: var(--secondary-text-color);
    transition: 1s ease;
}

.continue-button button::before {
    top: -1em;
    left: -1em;
}

.continue-button button::after {
    left: calc(100% + 1em);
    top: calc(100% + 1em);
}

.continue-button button:hover::before,
.continue-button button:hover::after {
    height: 410px;
    width: 410px;
}

.continue-button button:hover {
    color: var(--primary-text-color);
}

.continue-button button:active {
    filter: brightness(.8);
}
  
  /* Next section styling */

  .next-section-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
  }

  .next-section {
    background-color: var(--background-color);
    padding: 20px;
    margin-top: 20px;
    border-radius: 8px;
    min-height: 300px; /* Ensure enough height for the next section to allow scrolling */
    font-size: 14px;
    width: 50vw;
    max-width: 500px;
  }
  
  /* Responsive Design */
  @media (max-width: 768px) {

    .split-sides {
        display: flex;
        flex-wrap: wrap;
        gap: 0%;
      }
    
      .left-section {
        width: 100%;
      }
    
      .right-section {
        width: 100%;
      }

    .contact-page form {
      padding: 10px;
      font-size: 13px; /* Make the font smaller for mobile */
      margin-top: 200px;
    }
  
    .contact-page button {
      font-size: 13px;
    }
  
    .contact-page input[type="text"],
    .contact-page input[type="email"],
    .contact-page input[type="tel"],
    .contact-page input[type="number"],
    .contact-page select,
    .contact-page textarea {
      font-size: 13px;
      padding: 6px;
    }
  }
  