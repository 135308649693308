.accomplishmentsContainer {
  display: grid;
  position: sticky;
  top: 0;
  width: 100%;
  height: 100vh;
  background-color: transparent;
  grid-template-rows: 1fr auto 1fr; /* Add flexible rows to center content vertically */
  grid-template-columns: repeat(2, 1fr);
  box-sizing: border-box;
  justify-items: center; /* Horizontally center grid items */
  align-items: center; /* Vertically center grid items */
  padding-top: 50px; /* Adjust to move everything down slightly */
}

.leftTextContainer {
  grid-area: 1 / 1 / 2 / 2;
  display: flex;
  justify-content: center; /* Horizontally center */
  flex-direction: column;
  align-items: center; /* Vertically center */
  box-sizing: border-box;
  width: 60%;
  margin-left: 30%;
  position: relative;
  top: 50%;
}

.leftTextContainer > .shape {
  width: 100%;
  display: flex;
  justify-content: center; /* Center horizontally */
  position: relative;
}

.rightTextContainer {
  grid-area: 1 / 2 / 2 / 3;
  display: flex;
  justify-content: center; /* Horizontally center */
  flex-direction: column;
  align-items: center; /* Vertically center */
  box-sizing: border-box;
  width: 60%;
  margin-right: 30%;
  position: relative;
  top: 50%; /* Adjust to ensure alignment with the middle of the left text container */
}

.rightText {
  color: var(--primary-text-color);
  font-size: 18px;
  line-height: 1.5;
  transition: opacity 0.5s ease-in-out;
  text-align: center; /* Center-align the text */
  width: 100%; /* Full width for better text alignment */
}

.shape {
  border-radius: 20px;
  margin: 40px 0;
  position: relative;
  color: var(--secondary-text-color);
}

.selectedText {
  opacity: 1;
}

.rectangle {
  opacity: 0.1;
}

.transition {
  transition: all 0.5s ease-in-out;
}

.title {
  position: absolute;
  font-size: 45px;
  color: var(--primary-text-color);
  transition: all 0.5s ease-in-out;
  text-align: center;
  line-height: 0.5;
  font-weight: 500;
}

.highlightAccomplishment {
  color: var(--secondary-text-color);
  font-size: 40px;
}

.highlightTextAccomplishment {
  color: var(--secondary-text-color);
  font-weight: 500;
}

.smallTextAccomplishment {
  font-size: 15px;
  font-weight: 300;
}

.hiddenTitle {
  top: 50%;
}

.logoCarousel {
  grid-area: 2 / 1 / 3 / 3;
  box-sizing: border-box;
  width: 70%;
  margin-inline: auto;
  height: 150px;
  max-height: 150px;
  overflow: hidden;
  display: flex;
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically */
  position: relative;
  mask-image: linear-gradient(
    to right,
    rgba(0, 0, 0, 0),
    rgba(0, 0, 0, 0.5) 20%,
    rgba(0, 0, 0, 1) 40%,
    rgba(0, 0, 0, 1) 60%,
    rgba(0, 0, 0, 0.5) 80%,
    rgba(0, 0, 0, 0)
  );
  top: 100%;
}

@media screen and (max-width: 768px) {
  .accomplishmentsContainer {
    grid-template-columns: 1fr; /* Single column for mobile */
    grid-template-rows: auto auto auto; /* Stack elements vertically */
    grid-template-areas:
      'leftText'
      'rightText'
      'logos'; /* Define layout areas for mobile */
    height: auto; /* Allow container to expand based on content */
    justify-items: center; /* Center horizontally */
    align-items: center; /* Center vertically */
    padding-top: 0; /* No extra padding for mobile */
  }

  .leftTextContainer {
    grid-area: leftText; /* Assign to grid area for mobile */
    justify-content: center;
    align-items: center; /* Center horizontally and vertically */
    text-align: center;
    width: 100%;
    height: 100%;
    padding: 20px;
    top: 30%;
    margin-left: 0%;
  }

  .rightTextContainer {
    grid-area: rightText; /* Assign to grid area for mobile */
    justify-content: flex-start; /* Align top to center relative to left container */
    align-items: center; /* Center horizontally */
    text-align: center;
    width: 80%;
    padding: 20px;
    position: relative;
    top: 30%; /* Aligns top of right text with the middle of left text */
    margin-right: 0%;
  }

  .rightText {
    width: 100%;
    text-align: center;
  }

  .logoCarousel {
    visibility: hidden;
    grid-area: logos;
    width: 80%;
    justify-content: center; /* Center logos horizontally */
    align-items: center; /* Center logos vertically */
    margin-top: 20px;
    position: relative;
    top: 60%;
  }

  .title {
    font-size: 30px; /* Adjust font size for mobile */
  }

  .highlightAccomplishment {
    font-size: 28px;
  }
}

@keyframes scrollAnimation {
  to {
    left: -200px;
  }
}

.logo {
  position: absolute;
  left: max(
    calc(150px * 37),
    100%
  ); /* Adjusted left calculation to account for reduced space */
  animation: scrollAnimation 30s linear infinite;
  width: 150px; /* Set width of the logo container */
  height: 60px; /* Set height of the logo container */
  filter: grayscale(1);
  background-color: var(--primary-text-color);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px; /* Adjust padding to control background size */
  border-radius: 10px;
}

.logo:hover {
  filter: grayscale(0);
}

.logo1 {
  animation-delay: calc(30s / 37 * (37 - 1) * -1);
}
.logo2 {
  animation-delay: calc(30s / 37 * (37 - 2) * -1);
}
.logo3 {
  animation-delay: calc(30s / 37 * (37 - 3) * -1);
}
.logo4 {
  animation-delay: calc(30s / 37 * (37 - 4) * -1);
}
.logo5 {
  animation-delay: calc(30s / 37 * (37 - 5) * -1);
}
.logo6 {
  animation-delay: calc(30s / 37 * (37 - 6) * -1);
}
.logo7 {
  animation-delay: calc(30s / 37 * (37 - 7) * -1);
}
.logo8 {
  animation-delay: calc(30s / 37 * (37 - 8) * -1);
}
.logo9 {
  animation-delay: calc(30s / 37 * (37 - 9) * -1);
}
.logo10 {
  animation-delay: calc(30s / 37 * (37 - 10) * -1);
}
.logo11 {
  animation-delay: calc(30s / 37 * (37 - 11) * -1);
}
.logo12 {
  animation-delay: calc(30s / 37 * (37 - 12) * -1);
}
.logo13 {
  animation-delay: calc(30s / 37 * (37 - 13) * -1);
}
.logo14 {
  animation-delay: calc(30s / 37 * (37 - 14) * -1);
}
.logo15 {
  animation-delay: calc(30s / 37 * (37 - 15) * -1);
}
.logo16 {
  animation-delay: calc(30s / 37 * (37 - 16) * -1);
}
.logo17 {
  animation-delay: calc(30s / 37 * (37 - 17) * -1);
}
.logo18 {
  animation-delay: calc(30s / 37 * (37 - 18) * -1);
}
.logo19 {
  animation-delay: calc(30s / 37 * (37 - 19) * -1);
}
.logo20 {
  animation-delay: calc(30s / 37 * (37 - 20) * -1);
}
.logo21 {
  animation-delay: calc(30s / 37 * (37 - 21) * -1);
}
.logo22 {
  animation-delay: calc(30s / 37 * (37 - 22) * -1);
}
.logo23 {
  animation-delay: calc(30s / 37 * (37 - 23) * -1);
}
.logo24 {
  animation-delay: calc(30s / 37 * (37 - 24) * -1);
}
.logo25 {
  animation-delay: calc(30s / 37 * (37 - 25) * -1);
}
.logo26 {
  animation-delay: calc(30s / 37 * (37 - 26) * -1);
}
.logo27 {
  animation-delay: calc(30s / 37 * (37 - 27) * -1);
}
.logo28 {
  animation-delay: calc(30s / 37 * (37 - 28) * -1);
}
.logo29 {
  animation-delay: calc(30s / 37 * (37 - 29) * -1);
}
.logo30 {
  animation-delay: calc(30s / 37 * (37 - 30) * -1);
}
.logo31 {
  animation-delay: calc(30s / 37 * (37 - 31) * -1);
}
.logo32 {
  animation-delay: calc(30s / 37 * (37 - 32) * -1);
}
.logo33 {
  animation-delay: calc(30s / 37 * (37 - 33) * -1);
}
.logo34 {
  animation-delay: calc(30s / 37 * (37 - 34) * -1);
}
.logo35 {
  animation-delay: calc(30s / 37 * (37 - 35) * -1);
}
.logo36 {
  animation-delay: calc(30s / 37 * (37 - 36) * -1);
}
.logo37 {
  animation-delay: calc(30s / 37 * (37 - 37) * -1);
}
