.left-outer-circle {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1000;
    margin: 0;
    padding: 0;
    margin-top: 50vh;
    margin-left: 80px;
    cursor: pointer;
    background-color: grey;
    opacity: 50%;
    height: 30px;
    width: 30px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: transform 0.4s ease, background-color 0.4s ease;
}

.left-inner-circle {
    z-index: 1001;
    background-color: var(--background-color);
    border-radius: 50%;
    height: 30%;
    width: 30%;
    transition: transform 0.4s ease, background-color 0.4s ease;
    color: rgba(255, 255, 255, 0);
    font-size: 1.3px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.left-outer-circle:hover {
    background-color: var(--secondary-text-color);
    transform: scale(4);
}

.left-inner-circle:hover {
    background-color: var(--secondary-text-color);
    transform: scale(2.5);
    color: rgb(255, 255, 255);
}

@media (max-width: 600px) {
    .left-outer-circle {
        visibility: hidden;
    }
}