@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

.solutions-page {
  height: 100%;
  background-color: var(--background-color);
  font-family: 'Poppins', sans-serif;
  font-weight: 300;
  overflow-y: auto;
  scroll-behavior: smooth;
}

.container {
  height: 100vh;
  scroll-snap-type: y mandatory;
  overflow-y: scroll;
  scroll-behavior: smooth;
  scrollbar-width: none;
}

.container > div {
  scroll-snap-align: start;
  scroll-snap-stop: always;
}

.container::-webkit-scrollbar {
  display: none;
}

.solutions-scroll-section {
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 50px 20px;
}

:root {
  --background-color: rgb(13, 0, 66);
  --foreground-color: #8680a1;
  --primary-text-color: white;
  --secondary-text-color: rgb(253, 95, 13);
  --toggle-bg: rgb(13, 0, 66);
  --toggle-fg: white;
}

[data-theme='light'] {
  --background-color: white;
  --foreground-color: rgb(13, 0, 66);
  --primary-text-color: rgb(13, 0, 66);
  --secondary-text-color: rgb(253, 95, 13);
  --toggle-bg: white;
  --toggle-fg: rgb(13, 0, 66);
}
