.title-container {
  padding-top: 15vw; /* Adjusted for responsiveness */
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--primary-text-color);
}

#before-title {
  flex-direction: column;
  animation: FadeIn 1.5s ease-in-out;
  text-align: center;
}

#after-title {
  animation: FadeIn 1.5s ease-in-out;
  text-align: center;
}

.scroll {
  animation: scrollDown 2s ease-in-out;
}

@keyframes scrollDown {
  0% {
      transform: translateY(-100%);
  }
  10% {
      transform: translateY(0%);
  }
  90% {
      transform: translateY(0%);
  }
  100% {
      transform: translateY(100%);
  }
}

@keyframes FadeIn {
  0% {
      opacity: 0%;
  }
  100% {
      opacity: 100%;
  }
}

.highlightWord,
.highlightThinking {
  color: var(--secondary-text-color);
  display: inline;
  margin: 0 1vw; /* Adjusted margin for responsiveness */
}

.carousel-container {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 2rem;
}

.carousel {
  display: inline-block;
  height: 2rem;
  position: relative;
}

.word {
  position: absolute;
  animation: vertical-slide 2s infinite;
}

@keyframes vertical-slide {
  0% {
      transform: translateY(100%);
  }
  10% {
      transform: translateY(0);
  }
  90% {
      transform: translateY(0);
  }
  100% {
      transform: translateY(-100%);
  }
}

/* .title-container {
  padding-top: 15vw;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 50px;
  font-weight: 500;
  color: var(--primary-text-color);
}

#before-title {
  flex-direction: column;
  animation: FadeIn 1.5s ease-in-out;
  text-align: center;
}

#after-title {
  animation: FadeIn 1.5s ease-in-out;
}

.scroll {
  animation: scrollDown 2s ease-in-out;
}

@keyframes scrollDown {
  0% {
    transform: translateY(-100%);
  }
  10% {
    transform: translateY(0%);
  }
  90% {
    transform: translateY(0%);
  }
  100% {
    transform: translateY(100%);
  }
}

@keyframes FadeIn {
  0% {
    opacity: 0%;
  }
  100% {
    opacity: 100%;
  }
}

.highlightWord {
  color: var(--secondary-text-color);
  display: inline;
}

.highlightThinking {
  color: var(--secondary-text-color);
  display: inline;
  margin-left: 17px;
  margin-right: 17px;
}

.carousel-container {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 2rem;
}

.carousel {
  display: inline-block;
  height: 2rem;
  position: relative;
}

.word {
  position: absolute;
  animation: vertical-slide 2s infinite;
}

@keyframes vertical-slide {
  0% {
    transform: translateY(100%);
  }
  10% {
    transform: translateY(0);
  }
  90% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-100%);
  }
} */