.Services {
  height: 100%;
  background-color: var(--background-color);
  font-family: 'Poppins', sans-serif;
  font-weight: 300;
  overflow-y: auto;
  scroll-behavior: smooth;
}

/* Container for the scrollable divs */
.container {
  height: 100vh;
  scroll-snap-type: y mandatory;
  /* Snapping along the vertical axis */
  overflow-y: scroll;
  /* Enables vertical scrolling */
  scroll-behavior: smooth;
  /* Smooth scrolling between sections */
  scrollbar-width: none;
  /* Hides the scrollbar (optional for styling) */
}

.container>div {
  scroll-snap-align: start;
  /* Snaps to the top of the viewport */
  scroll-snap-stop: always;
  /* Ensures the div is fully on-screen before moving to the next */
}

/* Optional styling to remove the scrollbar for Webkit browsers */
.container::-webkit-scrollbar {
  display: none;
} 

.services-scroll-section {
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 50px 20px;
}