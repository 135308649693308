.valueComponentContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    overflow: hidden;
    height: 100vh;
    position: relative;
}

.valueComponentHeader {
    position: absolute;
    top: 10%;
    color: var(--primary-text-color);
    font-size: 50px;
    font-weight: 500;
    opacity: 0;
    transform: translateY(500px);
    transition: opacity 1s ease, transform 1s ease;
}

.valueComponentHeader.animate {
    opacity: 1;
    transform: translateY(20px);
}

.highlightValue {
    color: var(--secondary-text-color);
}

.valueCardWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    gap: 20px;
    flex-wrap: wrap;
    margin-top: 50px;
}

.valueItem {
    position: relative;
    display: flex;
    justify-content: center;
    transition: width 0.5s ease, height 0.5s ease;
}

.valueItem.collapsed {
    width: 200px;
    height: 300px;
    transition: width 0.5s ease, height 0.5s ease;
}

.valueItem.expanded {
    width: 60%;
    height: 80%;
    transition: width 0.5s ease, height 0.5s ease;
}

.valueCard {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    overflow: hidden;
    background-color: var(--primary-text-color);
    border-radius: 25px;
    transition: flex-direction 0.5s ease;
}

.valueCardFront {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
}

/* Responsive image sizing */
.sliderValueImage {
    width: 80%; /* Make image size responsive based on container width */
    max-width: 150px; /* Maximum size for collapsed state */
    height: auto;
    border-radius: 15px;
    border: 2px solid var(--primary-text-color);
    transition: width 0.5s ease, height 0.5s ease-out;
}

/* Adjust size for expanded state */
.expandedCard .sliderValueImage {
    width: 50%; /* Use percentage for fluid resizing */
    max-width: 350px; /* Maximum size for expanded state */
    height: auto;
}

.cardContent {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
    color: var(--background-color);
    text-align: center;
}

.cardContent h1 {
    font-size: 18px;
    font-weight: 500;
    margin-top: 10px;
    margin-bottom: 10px;
}

.expandButton {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
    font-size: 20px;
    color: var(--background-color);
    background: none;
    border: none;
    padding: 5px;
    margin-right: 5px;
}

.expandedCard {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin: 1%;
}

.expandedCard .cardContent {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-left: 3%;
    text-align: left;
    margin-bottom: 5%;
}

.expandedCard .cardContent h1 {
    font-size: 22px;
    font-weight: 500;
    text-align: center;
    width: 90%;
    margin-top: 10%;
}

.expandedCard .descriptionContainer p {
    font-size: 15px;
    text-align: left;
    line-height: 1.5;
    margin-right: 10%;
}

.cardContent h1 {
    font-size: 18px;
    font-weight: 300;
    color: var(--background-color);
    border-radius: 10px;
    width: 90%;
}

.valueItem:not(.expandedCard) .cardContent {
    align-items: center;
}

/* Mobile responsive adjustments */
@media (max-width: 768px) {
    .valueComponentHeader > h1 {
        font-size: 30px;
        top: 10%;
    }

    .valueCardWrapper {
        gap: 10px;
        margin-top: 30px;
    }

    .valueItem.collapsed {
        width: 150px;
        height: 200px;
    }

    .valueItem.expanded {
        width: 80%;
        height: 110%;
    }

    .sliderValueImage {
        width: 50%;
        max-width: 100px;
        height: auto;
    }

    .expandedCard .sliderValueImage {
        width: 50%;
        max-width: 100px;
        height: auto;
    }

    .cardContent h2 {
        font-size: 16px;
    }

    .expandedCard .cardContent h2 {
        font-size: 15px;
    }

    .expandedCard .descriptionContainer p {
        font-size: 12px;
        margin-right: 5%;
    }

    .expandedCard .expandButton {
        top: -5px;
    }
}
