/* Container for the hamburger menu */
.hamburger-menu {
  position: relative;
  z-index: 1001; /* Ensures it's in front of the menu */
}

/* Style the hamburger icon */
/* Style the hamburger icon */
.hamburger-icon {
  position: fixed;
  top: 0;
  right: 0;
  margin-top: 80px;
  margin-right: 80px;
  cursor: pointer;
  color: var(--primary-text-color); /* Default color (white) */
  z-index: 1002; /* Higher z-index than the menu overlay */
  transition: color 0.3s ease;
}

.hamburger-icon:hover,
.hamburger-icon.open {
  color: var(--secondary-text-color); /* Change to secondary color on hover or open */
}


/* Fullscreen overlay for the menu */
.menu-overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  height: 100vh;
  width: 100%;
  background-color: var(--background-color);
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s ease;
  display: flex;
  justify-content: right;
  align-items: start;
  z-index: 1000; /* Lower z-index than the hamburger icon */
}

/* Open state of the menu */
.menu-overlay.open {
  opacity: 1;
  visibility: visible;
}

/* Menu links style */
.menu-links {
  list-style-type: none;
  padding-right: 7vw;
  padding-top: 10vw;
  margin: 0;
  text-align: right;
}

.menu-links li {
  margin: 0 0;
}

.menu-links li a {
  color: #fff;
  font-size: 2.3em;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  text-decoration: none;
  transition: color 0.3s ease;
}

.menu-links li a:hover {
  color: var(--secondary-text-color);
}

/* Responsive styles */
@media (max-width: 768px) {
  /* Adjust hamburger icon for tablets and smaller screens */
  .hamburger-icon {
    margin-top: 40px;
    margin-right: 40px;
    font-size: 30px;
  }

  /* Adjust menu padding for tablets */
  .menu-links {
    padding-right: 4vw;
    padding-top: 8vw;
  }

  .menu-links li a {
    font-size: 2em; /* Slightly smaller font size for tablets */
  }

  /* Menu overlay opens just below the hamburger menu for screens < 768px */
  .menu-overlay.open {
    top: 40px; /* Below the hamburger icon */
    height: calc(100vh - 40px); /* Adjust height to fit below the hamburger icon */
  }
}

@media (max-width: 480px) {
  /* Adjust hamburger icon for mobile phones */
  .hamburger-icon {
    margin-top: 30px;
    margin-right: 30px;
    font-size: 25px;
  }

  /* Adjust menu padding for mobile phones */
  .menu-links {
    padding-right: 8vw;
    padding-top: 6vw;
  }

  .menu-links li a {
    font-size: 1.8em; /* Smaller font size for mobile phones */
  }

  /* Menu overlay opens just below the hamburger menu */
  .menu-overlay.open {
    top: 60px; /* Adjusted for mobile screens */
    height: calc(100vh - 60px); /* Ensure it opens below the hamburger menu */
  }
}
