.servicesComponentTitle {
    color: var(--primary-text-color);
    font-size: 250px; /* Default font size for larger screens */
    font-weight: 500;
    position: absolute;
    top: 50%;
    left: 100%; /* Start off-screen to the right */
    transform: translateY(-50%); /* Center the text vertically */
    white-space: nowrap; /* Prevent text wrapping */
    animation: scroll 2s linear forwards; /* Scroll animation across the screen in 2 seconds */
    display: flex;
    justify-content: center;
    align-items: center;
}

/* Adjusted keyframe to give more room at the end */
@keyframes scroll {
    0% {
        transform: translateX(100vw) translateY(-50%); /* Start off-screen to the right */
    }
    100% {
        transform: translateX(-150vw) translateY(-50%); /* End further off-screen to the left */
    }
}

/* Tablet view adjustments */
@media (max-width: 768px) {
    .servicesComponentTitle {
        font-size: 120px; /* Adjust font size for tablets */
        animation: scroll 2s linear forwards;
    }
}

/* Mobile view adjustments */
@media (max-width: 480px) {
    .servicesComponentTitle {
        font-size: 70px; /* Adjust font size for mobile phones */
        animation: scroll 2s linear forwards;
    }
}
 
.highlightServices {
    color: var(--secondary-text-color);
}
