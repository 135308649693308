.learnButton {
    --color: var(--primary-text-color);
    width: 150px;
    /* max-width: 200px; */
    padding: 0.8em 2em;
    background-color: transparent;
    border-radius: 30px;
    position: relative;
    overflow: hidden;
    cursor: pointer;
    transition: 0.5s;
    font-weight: 300;
    font-size: 0.75rem;
    border: 1px solid;
    font-family: "Poppins", sans-serif;
    text-transform: uppercase;
    border-color: var(--secondary-text-color);
    color: var(--color);
    z-index: 1;
}

.learnButton::before,
.learnButton::after {
    content: '';
    display: block;
    width: 35px;
    height: 35px;
    transform: translate(-50%, -50%);
    position: absolute;
    border-radius: 50%;
    z-index: -1;
    background-color: var(--secondary-text-color);
    transition: 1s ease;
}

.learnButton::before {
    top: -1em;
    left: -1em;
}

.learnButton::after {
    left: calc(100% + 1em);
    top: calc(100% + 1em);
}

.learnButton:hover::before,
.learnButton:hover::after {
    height: 410px;
    width: 410px;
}

.learnButton:hover {
    color: var(--primary-text-color);
}

.learnButton:active {
    filter: brightness(.8);
}