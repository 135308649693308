.scTitle {
  color: var(--primary-text-color);
  position: absolute;
  top: 50%;
  left: 100%;
  transform: translateY(-50%);
  white-space: nowrap;
  animation: scroll 5s cubic-bezier(0.25, 1, 0.5, 1) forwards,
    fadeOut 5s ease-out forwards;
  display: flex;
  justify-content: center;
  align-items: center;
}

.scTitle>h1 {
  font-size: 250px;
  font-weight: 500;
}

/* Ultra-smooth scrolling animation */
@keyframes scroll {
  0% {
    transform: translateX(100vw) translateY(-50%);
  }

  100% {
    transform: translateX(-150vw) translateY(-50%);
  }
}

/* Ultra-smooth, extended fade-out effect */
@keyframes fadeOut {
  0% {
    opacity: 1;
  }

  20% {
    opacity: 0.95;
  }

  35% {
    opacity: 0.85;
  }

  50% {
    opacity: 0.75;
  }

  60% {
    opacity: 0.6;
  }

  70% {
    opacity: 0.5;
  }

  80% {
    opacity: 0.4;
  }

  85% {
    opacity: 0.3;
  }

  90% {
    opacity: 0.2;
  }

  95% {
    opacity: 0.1;
  }

  100% {
    opacity: 0;
  }
}

/* Tablet view adjustments */
@media (max-width: 768px) {
  .scTitle {
    animation: scroll 2s linear forwards;
  }
  .scTitle>h1 {
    font-size: 120px;
  }
}

/* Mobile view adjustments */
@media (max-width: 480px) {
  .scTitle {
    animation: scroll 2s linear forwards;
  }
  .scTitle>h1 {
    font-size: 70px;
  }
}

.highlightSolutions {
  color: var(--secondary-text-color);
}