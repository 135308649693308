.footerContainer {
    display: flex;
    flex-direction: column;
    align-items: center; 
    justify-content: space-between; 
    height: 100vh; /* Changed from height to min-height */
    top: 0;
}

.footerSub {
    color: var(--primary-text-color);
    text-align: center;
    font-size: 18px;
    margin-top: 10%;
}

.footerHighlight {
    color: var(--secondary-text-color);
}

.footerEmail {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 10px;
    margin-bottom: 7%; /* Add some space below */
}

.emailInputContainer {
    position: relative;
    width: 300px;
}

.emailInput {
    padding: 10px 50px 10px 10px; 
    width: 80%;
    font-size: 12px;
    border: 1px solid var(--foreground-color);
    border-radius: 25px;
    font-family: "Poppins", sans-serif;
    transition: border-color 0.3s ease;
    height: 25px; 
    margin-bottom: 0px;
}

.emailInput:focus {
    outline: none;
}

.emailInput::placeholder {
    position: absolute;
    left: 20px;
    color: var(--foreground-color);
}

.submitArrowButton {
    position: absolute;
    top: 50%;
    right: 2px;
    transform: translateY(-50%);
    background-color: var(--background-color);
    border: none;
    color: white;
    font-size: 18px; 
    width: 36px; 
    height: 36px; 
    border-radius: 50%; 
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    padding: 0;
}

.submitArrowButton:hover {
    background-color: var(--background-color); 
}

.submitArrowButton:focus {
    outline: none;
}

.footerServices {
    display: flex;
    justify-content: center; /* Center the service lists */
    position: relative;
    gap: 100px; /* Adjusted gap between lists */
    width: 50%;
    padding: 0;
    margin: 0;
    top: -5%;
}

.footerServices ul {
    font-size: 15px;
    list-style: none; 
    color: var(--primary-text-color);
    padding: 0; 
    margin: 0;
    flex: 1; /* Ensures each list takes equal space */
}

.footerServices li {
    width: 100%;
    margin-bottom: 10px;
}

.footerServices .firstList li {
    text-align: center;
}
.footerServices .secondList li {
    text-align: left;
}

.footerServices a {
    text-decoration: none;
    color: inherit; 
}

.footerServices a:hover {
    text-decoration: underline;
    color: inherit; 
}

.footerCopyright {
    font-size: 12px;
    text-align: center;
    color: var(--foreground-color);
    margin-top: 20px;
}

@media (max-width: 768px) {
    .footerSub {
        margin-top: 50%;
    }
}