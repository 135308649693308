.philosophyContainer {
  height: 100vh;
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: center;
}

.philosophyTitle {
  color: var(--primary-text-color);
  font-size: 70px; /* Default font size for larger screens */
  top: 50%; /* Center it vertically */
  transform: translateY(-15%); /* Adjust for vertical centering */
  font-weight: 500;
  transition: font-size 0.5s ease, top 0.5s ease; /* Smooth transition */
  text-align: center;
  width: 100%; /* Make sure title takes up full width on mobile */
}

.philosophyTitle.animate {
  font-size: 50px; /* Smaller size after animation */
  top: 15%;
}

.highlightPhilosophy {
  color: var(--secondary-text-color);
  font-weight: 500;
}

.philosphyParagraph {
  width: 40%; /* Default width for larger screens */
  line-height: 1.7;
  color: var(--primary-text-color);
  text-align: center;
  opacity: 0;
  font-size: 18px;
  filter: blur(10px);
  transition: opacity 0.5s ease-in-out, filter 0.5s ease-in-out;
}

.philosphyParagraph.show {
  opacity: 1;
  filter: blur(0px);
}

.philosphyParagraph.hide {
  opacity: 0;
  filter: blur(5px);
}

.newsButton {
  --color: var(--primary-text-color);
  width: 10vw;
  max-width: 200px;
  padding: 0.8em 2em;
  background-color: transparent;
  border-radius: 30px;
  position: relative;
  overflow: hidden;
  cursor: pointer;
  transition: 0.5s;
  font-weight: 300;
  font-size: 0.75rem;
  border: 1px solid;
  font-family: "Poppins", sans-serif;
  text-transform: uppercase;
  border-color: var(--secondary-text-color);
  color: var(--primary-text-color);
  z-index: 1;
}

.newsButton::before,
.newsButton::after {
  content: '';
  display: block;
  width: 35px;
  height: 35px;
  transform: translate(-50%, -50%);
  position: absolute;
  border-radius: 50%;
  z-index: -1;
  background-color: var(--secondary-text-color);
  transition: 1s ease;
}

.newsButton::before {
  top: -1em;
  left: -1em;
}

.newsButton::after {
  left: calc(100% + 1em);
  top: calc(100% + 1em);
}

.newsButton:hover::before,
.newsButton:hover::after {
  height: 410px;
  width: 410px;
}

.newsButton:hover {
  color: var(--primary-text-color);
}

.newsButton:active {
  filter: brightness(.8);
}

/* Media queries for smaller screens */
@media (max-width: 768px) {
  .philosophyTitle {
    font-size: 50px; /* Smaller title font size for tablets */
    top: 40%; /* Adjust top position */
  }

  .philosophyTitle.animate {
    font-size: 40px; /* Adjust after animation for tablets */
    top: 20%;
  }

  .philosphyParagraph {
    width: 60%; /* Increase paragraph width for smaller screens */
    font-size: 16px; /* Slightly smaller text */
  }
}

@media (max-width: 480px) {
  .philosophyTitle > h1 {
    font-size: 40px; /* Smaller title for mobile */
    top: 35%; /* Adjust top position for mobile */
  }

  .philosophyTitle.animate > h1{
    font-size: 30px; /* Even smaller after animation for mobile */
    top: 25%;
  }

  .philosphyParagraph {
    width: 80%; /* Full width paragraph for mobile */
    font-size: 14px; /* Smaller text on mobile */
  }
}
