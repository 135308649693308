.enter-container {
    margin: 0;
    padding: 0;
    top: 0;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: var(--background-color);
}

.enter-logo {
    width: 35vw; 
    max-width: 400px; 
    justify-self: center;
    align-self: center;
    animation: beat .5s infinite alternate;
    transform-origin: center;
    margin-top: 20vh; 
}

@keyframes beat {
    to {
        transform: scale(1.25);
    }
}

.enter-button {
    flex: 2;
    justify-self: center; 
    align-self: center;
}

.enterButton {
    --color: var(--primary-text-color);
    width: 10vw; 
    max-width: 200px; 
    padding: 0.8em 2em; 
    background-color: transparent;
    border-radius: 30px;
    position: relative;
    overflow: hidden;
    cursor: pointer;
    transition: 0.5s;
    font-weight: 300;
    font-size: 0.75rem; 
    border: 1px solid;
    font-family: "Poppins", sans-serif;
    text-transform: uppercase;
    border-color: var(--secondary-text-color);
    color: var(--color);
    z-index: 1;
}

.enterButton::before,
.enterButton::after {
    content: '';
    display: block;
    width: 35px;
    height: 35px;
    transform: translate(-50%, -50%);
    position: absolute;
    border-radius: 50%;
    z-index: -1;
    background-color: var(--secondary-text-color);
    transition: 1s ease;
}

.enterButton::before {
    top: -1em;
    left: -1em;
}

.enterButton::after {
    left: calc(100% + 1em);
    top: calc(100% + 1em);
}

.enterButton:hover::before,
.enterButton:hover::after {
    height: 410px;
    width: 410px;
}

.enterButton:hover {
    color: var(--primary-text-color);
}

.enterButton:active {
    filter: brightness(.8);
}

@media (max-width: 768px) {
    .enter-logo {
        width: 60vw; 
        margin-top: 15vh;
    }

    .enterButton {
        width: 10vw;
        max-width: 180px;
        font-size: 0.6rem; 
    }
}

@media (max-width: 480px) {
    .enter-logo {
        width: 70vw; 
        margin-top: 25vh;
    }

    .enterButton {
        width: 15vw; 
        max-width: 160px;
        font-size: 0.5rem; 
        padding: 1em 1.4em; 
    }
}