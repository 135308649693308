@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

.App {
  height: 100%;
  background-color: var(--background-color);
  font-family: 'Poppins', sans-serif;
  font-weight: 300;
}

:root {
  --background-color: rgb(13, 0, 66);
  --foreground-color: #8680a1;
  --primary-text-color: white;
  --secondary-text-color: rgb(253, 95, 13);
  --toggle-bg: rgb(13, 0, 66);
  --toggle-fg: white;
}

[data-theme='light'] {
  --background-color: white;
  --foreground-color: rgb(13, 0, 66);
  --primary-text-color: rgb(13, 0, 66);
  --secondary-text-color: rgb(253, 95, 13);
  --toggle-bg: white;
  --toggle-fg: rgb(13, 0, 66);
}

/* Container for the scrollable divs */
.container {
  height: 100vh;
  scroll-snap-type: y mandatory;
  /* Snapping along the vertical axis */
  overflow-y: scroll;
  /* Enables vertical scrolling */
  scroll-behavior: smooth;
  /* Smooth scrolling between sections */
  scrollbar-width: 0px;
}

.container>div {
  scroll-snap-align: start;
  /* Snaps to the top of the viewport */
  scroll-snap-stop: always;
  /* Ensures the div is fully on-screen before moving to the next */
}

.header-tile {
  margin: 0px 40px !important;
}

/* Optional styling to remove the scrollbar for Webkit browsers */
.container::-webkit-scrollbar {
  display: none;
}

@media (max-width: 480px) {
  .container {
    overflow-x: hidden;
  }

  .text-bubble-dark1-gif,
  .text-bubble-dark2-gif,
  .text-bubble-dark3-gif {
    margin: 0px !important;
  }
}

/* Hide the SkipButton initially */
.SkipButton.hidden {
  display: none;
}

/* When the button should be visible, remove the 'hidden' class */
.SkipButton.visible {
  display: block;
}

@media (max-width: 666px) {
  .SkipButton.visible {
    display: none;
  }
}