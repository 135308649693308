.ServicesComponent1Container {
    height: 100vh;
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: center;
    align-items: center;
    position: sticky;
    top: 0;
    z-index: 10;
    overflow: visible;
    scroll-snap-align: start;
    scroll-padding-top: 80px;
}

.servicesComponent_title {
    color: var(--primary-text-color);
    font-size: 60px;
    font-weight: 500;
    text-align: center;
    width: 100%;
    opacity: 0;
    transition: font-size 0.5s ease, transform 0.5s ease, opacity 0.5s ease;
    margin-bottom: 20px;
}

.servicesComponent_title.show {
    opacity: 1;
}

.servicesComponent_title.shrink {
    font-size: 50px;
    transform: translateY(-10px);
}

.ServicesComponent1Text {
    width: 45%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    opacity: 0;
}

.ServicesComponent1Text.show {
    opacity: 1;
    transform: translateY(0);
}

.ServicesComponent1Text > p {
    color: var(--primary-text-color);
    font-size: 24px;
    font-weight: 200;
    margin: 0; /* Reset margin for the paragraph */
}

.highlightServices1 {
    color: var(--secondary-text-color);
    font-weight: 500;
}

@media (max-width: 768px) {
    .ServicesComponent1Text {
        width: 60%;
    }

    .ServicesComponent1Text > p {
        color: var(--primary-text-color);
        font-size: 20px;
        font-weight: 200;
        margin: 0;
    }
}

@media (max-width: 480px) {
    .servicesComponent_title {
        color: var(--primary-text-color);
        font-size: 45px;
        font-weight: 500;
        text-align: center;
        width: 100%;
        opacity: 0;
        transition: font-size 0.5s ease, transform 0.5s ease, opacity 0.5s ease;
    }

    .servicesComponent_title.show {
        opacity: 1;
    }

    .servicesComponent_title.shrink {
        font-size: 35px;
        transform: translateY(-10px);
    }

    .ServicesComponent1Text {
        width: 70%;
    }

    .ServicesComponent1Text>p {
        color: var(--primary-text-color);
        font-size: 15px;
        font-weight: 200;
        margin: 0;
    }
}