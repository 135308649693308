.nav-logo {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1002;
    margin: 0;
    padding: 0;
    margin-top: 30px;
    margin-left: 30px;
    width: 200px; /* Default width for larger screens */
    /* animation: beat 0.5s infinite alternate; */
    cursor: pointer;
}

/* @keyframes beat {
    to {
        transform: scale(1.25);
    }
} */

/* Tablet and smaller screen adjustments */
@media (max-width: 768px) {
    .nav-logo {
        width: 150px; /* Adjust the logo size for tablets */
        margin-top: 30px; /* Adjust top margin */
        margin-left: 30px; /* Adjust left margin */
    }
}

/* Mobile phone adjustments */
@media (max-width: 480px) {
    .nav-logo {
        width: 100px; /* Adjust the logo size for mobile phones */
        margin-top: 20px; /* Adjust top margin */
        margin-left: 20px; /* Adjust left margin */
    }
}
