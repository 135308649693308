/* .container-style {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    height: 100vh;
    background-color: var(--background-color);
    color: white;
    text-align: center;
}

.header-image-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
}

.header {
    position: relative;
    text-align: left;
    left: -20%;
    width: 50%;
    font-size: 50px;
    font-weight: 600;
    margin: 0;
    top: 10%;
}

.smallTextIntro {
    color: var(--foreground-color);
    font-size: 12px;
}

.slide-images {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    width: 50%;
    gap: 20px;
    position: absolute;
    top: 10%;
    right: -10%;
}

.slide-image {
    width: 90%;
    max-width: 300px;
    height: auto;
}

.slide-image1 {
    width: 30%;
}

.body-text {
    text-align: left;
    position: relative;
    left: -20%;
    width: 50%;
    top: -5%;
    font-size: 20px;
    margin: 20px auto;
    max-width: 1200px;
}

.footer {
    top: -10%;
    width: 70%;
}

.footer1 {
    position: absolute;
    width: 30%;
    height: auto;
    bottom: 17%;
    left: 35%;
}

.footer2 {
    position: absolute;
    width: 50%;
    bottom: 20%;
    left: 25%;
    height: auto;
    margin-top: -10px;
}

.carousel {
    display: flex;
    justify-content: center;
    align-items: center;
    top: -17%;
    margin-top: -30px;
    margin-bottom: -30px;
}

.carousel button {
    width: 15px;
    height: 15px;
    border: none;
    border-radius: 50%;
    cursor: pointer;
    transition: background-color 0.3s;
    margin: 0 7.5px;
}

.carousel button.active {
    background-color: var(--secondary-text-color);
    color: var(--secondary-text-color);
}

.register-button {
    text-align: center;
    position: absolute;
    top: 80%;
    left: 7%;
}

.registerButton a {
    text-decoration: none;
    color: var(--primary-text-color);
}

.registerButton {
    --color: var(--primary-text-color);
    width: 10vw;
    max-width: 200px;
    padding: 0.8em 2em;
    background-color: transparent;
    border-radius: 30px;
    position: relative;
    overflow: hidden;
    cursor: pointer;
    transition: 0.5s;
    font-weight: 300;
    font-size: 0.75rem;
    border: 1px solid;
    font-family: "Poppins", sans-serif;
    text-transform: uppercase;
    border-color: var(--secondary-text-color);
    color: var(--primary-text-color);
    z-index: 1;
}

.registerButton::before,
.registerButton::after {
    content: '';
    display: block;
    width: 35px;
    height: 35px;
    transform: translate(-50%, -50%);
    position: absolute;
    border-radius: 50%;
    z-index: -1;
    background-color: var(--secondary-text-color);
    transition: 1s ease;
}

.registerButton::before {
    top: -1em;
    left: -1em;
}

.registerButton::after {
    left: calc(100% + 1em);
    top: calc(100% + 1em);
}

.registerButton:hover::before,
.registerButton:hover::after {
    height: 410px;
    width: 410px;
}

.registerButton:hover {
    color: var(--primary-text-color);
}

.registerButton:active {
    filter: brightness(.8);
}

.intro-button {
    position: absolute;
    margin-top: 10px;
    text-align: center;
    bottom: 5%;
}

.introButton {
    --color: var(--primary-text-color);
    width: 10vw;
    max-width: 200px;
    padding: 0.8em 2em;
    background-color: transparent;
    border-radius: 30px;
    position: relative;
    overflow: hidden;
    cursor: pointer;
    transition: 0.5s;
    font-weight: 300;
    font-size: 0.75rem;
    border: 1px solid;
    font-family: "Poppins", sans-serif;
    text-transform: uppercase;
    border-color: var(--secondary-text-color);
    color: var(--primary-text-color);
    z-index: 1;
}

.introButton::before,
.introButton::after {
    content: '';
    display: block;
    width: 35px;
    height: 35px;
    transform: translate(-50%, -50%);
    position: absolute;
    border-radius: 50%;
    z-index: -1;
    background-color: var(--secondary-text-color);
    transition: 1s ease;
}

.introButton::before {
    top: -1em;
    left: -1em;
}

.introButton::after {
    left: calc(100% + 1em);
    top: calc(100% + 1em);
}

.introButton:hover::before,
.introButton:hover::after {
    height: 410px;
    width: 410px;
}

.introButton:hover {
    color: var(--primary-text-color);
}

.introButton:active {
    filter: brightness(.8);
}

@media (max-width: 768px) {
    .header-image-wrapper {
        flex-direction: column;
        align-items: center;
        text-align: center;
    }

    .header {
        width: 100%;
    }
    .slide-images {
        display: none;
    }

    .body-text {
        font-size: 18px;
        width: 90%;
        text-align: center;
        margin: 20px auto;
    }

    .footer {
        width: 90%;
        text-align: center;
        margin: 20px auto;
        position: static;
    }

    .carousel {
        margin: 20px 0;
        position: absolute; 
        bottom: 10%;
        justify-content: center;
    }

    .registerButton {
        width: 80%;
        font-size: 1.1rem;
        padding: 1em 2em;
        position: relative; 
        top: -30px; 
        margin-bottom: 15px;
    }

    .introButton {
        width: 80%;
        font-size: 1.1rem;
        padding: 1em 2em;
    }
}


@media (max-width: 480px) {
    .container-style {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 100vh;
        text-align: center;
    }

    .header {
        font-size: 28px;
        width: 90%;
        text-align: center;
        left: 0;
        top: 0;
    }

    .slide-images {
        display: none;
    }

    .slide-image,
    .slide-image1 {
        display: none;
    }

    .body-text {
        font-size: 16px;
        width: 90%;
        text-align: center;
        margin: 20px 0;
        position: static;
    }

    .footer {
        width: 90%;
        margin: 20px 0;
        position: static;
        text-align: center;
    }

    .carousel {
        margin: 20px 0;
        position: absolute;
        bottom: 8%; 
        justify-content: center;
    }

    .registerButton {
        width: 90%;
        font-size: 1.2rem;
        padding: 1.2em 2em;
        position: relative; 
        top: -50px; 
        margin-bottom: 20px;
    }

    .introButton {
        width: 90%;
        font-size: 1.2rem;
        padding: 1.2em 2em;
    }
} */

.container-style {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    position: relative;
    background-color: var(--background-color);
    background-size: cover;
    background-position: center;
    padding: 0 5vw; 
}

.description {
    color: var(--primary-text-color);
    text-align: center;
    margin: 20px 0; 
    width: 100%;
    max-width: 800px; 
    font-family: "Poppins", sans-serif;
}

.description h1 {
    font-size: 1.5rem; 
    font-weight: 300;
}

.highlightIntroText {
    color: var(--secondary-text-color);
    font-weight: 500;
}

.smallIntroText {
    color: var(--secondary-text-color);
    font-size: 0.9rem; 
}

.carousel {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px; 
}

.carousel button {
    width: 15px;
    height: 15px;
    border: none;
    border-radius: 50%;
    cursor: pointer;
    transition: background-color 0.3s;
    margin: 0 7.5px; 
}

.carousel button.active {
    background-color: var(--secondary-text-color);
    color: var(--secondary-text-color);
}

.intro-button {
    text-align: center;
    margin-top: 20px; 
}

.introButton {
    --color: var(--primary-text-color);
    width: 20vw; 
    max-width: 200px;
    padding: 0.8em 2em; 
    background-color: transparent;
    border-radius: 30px;
    position: relative;
    overflow: hidden;
    cursor: pointer;
    transition: 0.5s;
    font-weight: 300;
    font-size: 0.75rem; 
    border: 1px solid;
    font-family: "Poppins", sans-serif;
    text-transform: uppercase;
    border-color: var(--secondary-text-color);
    color: var(--color);
    z-index: 1;
}

.introButton::before,
.introButton::after {
    content: '';
    display: block;
    width: 35px;
    height: 35px;
    transform: translate(-50%, -50%);
    position: absolute;
    border-radius: 50%;
    z-index: -1;
    background-color: var(--secondary-text-color);
    transition: 1s ease;
}

.introButton::before {
    top: -1em;
    left: -1em;
}

.introButton::after {
    left: calc(100% + 1em);
    top: calc(100% + 1em);
}

.introButton:hover::before,
.introButton:hover::after {
    height: 410px;
    width: 410px;
}

.introButton:hover {
    color: var(--primary-text-color);
}

.introButton:active {
    filter: brightness(.8);
}


@media (max-width: 768px) {
    .description {
        width: 100%; 
        max-width: 90%; 
    }

    .description h1 {
        font-size: 1.25rem; 
    }

    .smallIntroText {
        font-size: 0.8rem; 
    }

    .carousel button {
        border-radius: 50%;
    }

    .introButton {
        width: 20vw; 
        max-width: 180px; 
        font-size: 0.7rem; 
    }
}

@media (max-width: 480px) {
    .description {
        width: 100%; 
        max-width: 85%; 
    }

    .description h1 {
        font-size: 1.1rem; 
    }

    .carousel button {
        border-radius: 50%;
    }

    .smallIntroText {
        font-size: 0.75rem; 
    }

    .introButton {
        width: 20vw; 
        max-width: 160px; 
        font-size: 0.6rem; 
        padding: 0.6em 1.4em;
    }
}