/* Container Styling */
.sc2_container {
  height: 100vh;
  display: flex;
  flex-direction: column;
  width: 70%;
  max-width: 1400px;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-bottom: 100px;
  padding: 50px 20px;
  position: relative;
  overflow: hidden;
}

.sc2_title {
  color: white;
  font-size: 60px;
  font-weight: 500;
  opacity: 0;
  transition: opacity 0.5s ease-in-out, transform 0.5s ease-in-out,
    font-size 0.5s ease-in-out, margin-top 0.5s ease-in-out;
  width: 100%;
  position: relative;
  margin-bottom: 0; /* Changed to avoid movement */
  display: flex;
  justify-content: center;
  align-items: center;
}

.sc2_title.show {
  opacity: 1;
}

.sc2_title.shrink h2 {
  font-size: 40px;
  font-weight: 500;
}

/* Highlighted Text Styling */
.sc2_highlight {
  color: var(--secondary-text-color);
  font-weight: 500;
}

/* Paragraph Styling */
.sc2_paragraph {
  color: var(--primary-text-color);
  font-size: 18px;
  line-height: 1.8;
  opacity: 0;
  transform: translateY(20px);
  transition: opacity 0.5s ease-in-out, transform 0.5s ease-in-out;
  margin-top: 0;
}

.sc2_paragraph--show {
  opacity: 1;
  transform: translateY(0);
}

/* Content Styling */
.sc2_content {
  opacity: 0;
  transform: translateY(20px);
  transition: opacity 0.5s ease-in-out, transform 0.5s ease-in-out;
  margin-top: 10px;
  width: 100%;
}

.sc2_content--show {
  opacity: 1;
  transform: translateY(0);
}

/* Scroll Container with Custom Scrollbar */
.sc2_horizontalScrollContainer {
  display: flex;
  overflow-x: auto;
  overflow-y: hidden;
  white-space: nowrap;
  gap: 20px;
  padding: 20px 0;
  max-width: 100%;
  border-radius: 10px;
  background-color: rgba(255, 255, 255, 0.1);
  scroll-behavior: smooth;
  height: 320px;
  position: relative;
  align-items: center;
  transition: transform 0.4s ease-in-out;
  scroll-snap-type: y mandatory; /* Enable consistent snapping */
  scroll-padding: 20px; /* Padding to help consistent snapping from all directions */
}

.sc2_horizontalScrollContainer::-webkit-scrollbar {
  height: 6px;
}

.sc2_horizontalScrollContainer::-webkit-scrollbar-thumb {
  background: var(--secondary-text-color);
  border-radius: 10px;
}

/* Tile Styling */
.sc2_tile {
  background: linear-gradient(
    145deg,
    rgba(255, 255, 255, 0.1),
    rgba(255, 255, 255, 0.05)
  );
  backdrop-filter: blur(10px);
  border-radius: 15px;
  padding: 20px;
  text-align: center;
  cursor: pointer;
  min-width: 200px;
  max-width: 230px;
  flex: 0 0 auto;
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.2);
  transition: transform 0.4s ease, box-shadow 0.4s ease, background 0.4s ease,
    border 0.3s ease;
  color: var(--primary-text-color);
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  height: 280px;
}

.sc2_tile:hover {
  transform: translateY(-15px);
  box-shadow: 0 15px 30px rgba(0, 0, 0, 0.3);
  background: linear-gradient(
    145deg,
    rgba(255, 165, 0, 0.15),
    rgba(255, 165, 0, 0.1)
  );
  border: 2px solid var(--secondary-text-color);
}

/* Tile Image Styling */
.sc2_tileImage {
  width: 70px;
  height: 70px;
  margin-bottom: 15px;
  transition: transform 0.3s ease-in-out;
}

.sc2_tile:hover .sc2_tileImage {
  transform: rotate(10deg) scale(1.05);
}

/* Tile Title Styling */
.sc2_tileTitle {
  font-size: 16px;
  color: var(--secondary-text-color);
  font-weight: 600;
  margin: 10px 0;
  line-height: 1.2;
  white-space: normal;
  overflow: hidden;
  text-overflow: ellipsis;
  transition: color 0.3s ease;
}

.sc2_tile:hover .sc2_tileTitle {
  color: var(--primary-text-color);
}

/* Tile Description Styling */
.sc2_tileDescription {
  font-size: 14px;
  font-weight: 400;
  color: var(--primary-text-color);
  flex-grow: 1;
  margin: 10px 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
}

/* Learn More Button with Enhanced Hover Effect */
.sc2_learnMoreButton {
  background-color: var(--secondary-text-color);
  color: var(--primary-text-color);
  border: none;
  padding: 8px 16px;
  border-radius: 4px;
  cursor: pointer;
  text-decoration: none;
  font-weight: 500;
  margin-top: auto;
  transition: outline 0.3s ease, background-color 0.3s ease;
}

.sc2_learnMoreButton:hover {
  outline: 3px solid var(--primary-text-color);
  background-color: var(--primary-text-color);
  color: var(--secondary-text-color);
}

/* Media Queries for Responsive Design */
@media (max-width: 768px) {
  .sc2_title {
    font-size: 50px;
  }

  .sc2_horizontalScrollContainer {
    height: 300px;
    scroll-snap-type: y mandatory; /* Enable consistent snap scrolling */
    scroll-padding: 20px; /* Padding to help consistent snapping from all directions */
  }

  .sc2_tile {
    min-width: 180px;
    max-width: 200px;
    height: 260px;
  }

  .sc2_tileTitle {
    font-size: 15px;
  }

  .sc2_tileDescription {
    font-size: 13px;
  }

  .sc2_learnMoreButton {
    padding: 6px 12px;
  }
}

@media (max-width: 480px) {
  .sc2_title {
    color: var(--primary-text-color);
    font-size: 45px;
    font-weight: 500;
    opacity: 0;
    transform: translateY(20px);
    transition: opacity 0.5s ease-in-out, transform 0.5s ease-in-out,
      font-size 0.5s ease-in-out, margin-top 0.5s ease-in-out;
    width: 100%;
    position: relative;
    margin-bottom: 0; /* Changed to avoid movement */
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .sc2_title.show {
    opacity: 1;
    transform: translateY(0);
  }

  .sc2_title.shrink h2{
    font-size: 22px;
  }

  .sc2_horizontalScrollContainer {
    height: 270px;
    scroll-snap-type: y mandatory; /* Enable consistent snap scrolling */
    scroll-padding: 20px; /* Padding to help consistent snapping from all directions */
    /* margin-bottom: ; */
  }

  .sc2_tile {
    min-width: 160px;
    max-width: 180px;
    height: 240px;
  }

  .sc2_tileTitle {
    font-size: 14px;
  }

  .sc2_tileDescription {
    font-size: 12px;
  }

  .sc2_paragraph {
    color: var(--primary-text-color);
    font-size: 12px;
  }

  .sc2_learnMoreButton {
    padding: 5px 10px;
  }
}
