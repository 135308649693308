.keys-container {
  position: sticky;
  top: 0;
  display: flex;
  width: 100%;
  height: 100vh;
}

.keys-left {
  height: 100%;
  width: 55%;
  display: flex;
  flex-direction: row;
  justify-content: right;
}

.keys-header {
  width: 17vw;
  font-size: 35px;
  text-align: right;
  align-content: center;
}

.keys-text {
  color: var(--primary-text-color);
  width: 25vw;
  font-size: 18px;
}

.highlightText-keys {
  color: var(--secondary-text-color);
  font-weight: 500;
}

.keys-icons {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  /* width: 300px; */
}

.keys-icons img {
  padding: 50px;
  padding-left: 7vw;
  padding-right: 0px;
}

.keys-arrow {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: flex-start;
  /* width: 300px; */
}

.keys-arrow img {
  padding-left: 10px;
  padding-right: 10px;
  margin-bottom: 12vh;
}

.keys-right {
  height: 100%;
  width: 45%;
  display: flex;
  flex-direction: row;
  justify-content: left;
  margin-left: 1vw;
}

.keys-right-content-holder {
  width: 45vw;
  text-align: left;
  align-content: center;
}

.keys-right-header {
  color: var(--primary-text-color);
  width: 30vw;
  font-size: 30px;
}

@media (max-width: 900px) {
  .keys-header {
    display: none;
  }
  .keys-left {
    width: 35%;
  }
  .keys-right {
    width: 55%;
  }
  .keys-text,
  .keys-right-header {
    width: 55vw;
  }
}

@media (max-width: 666px) {
  .keys-icons {
    display: none;
  }
  .keys-arrow {
    display: none;
  }
  .keys-left {
    width: 20%;
  }
  .keys-right {
    width: 60%;
  }
  .keys-text,
  .keys-right-header {
    width: 60vw;
  }
}
