.blogComponentContainer {
    height: 100vh;
    margin: 0;
    top: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    position: sticky;
    width: 100%;
    overflow: hidden;
}

.blogItem {
    position: absolute;
    width: 250px;
    height: 300px;
    text-align: justify;
    border-color: var(--primary-text-color);
    padding: 20px;
    transition: 0.5s;
    left: calc(35% + 20px);
}

.sliderImage {
    border-radius: 25px;
    border: 2px solid var(--primary-text-color);
    width: 100%;  /* Set image width to 100% */
    height: auto; /* Maintain aspect ratio */
}

.blogCard {
    position: relative;
    width: 250px;
    height: 100%;
    perspective: 1000px;
    transform-style: preserve-3d; /* Ensure 3D transformation */
    transition: transform 0.6s ease-in-out;
}

.blogCardFace {
    position: absolute;
    width: 100%;
    height: 100%;
    backface-visibility: hidden;
    transition: transform 0.6s ease;
}

.blogCardFront {
    transform: rotateY(0deg); /* Front face is initially shown */
    width: 100%;
    height: 380px;
    backface-visibility: hidden; /* Hide backface when flipped */
}

/* Back face of the card */
.blogCardBack {
    transform: rotateY(180deg); /* Back face is hidden initially */
    display: flex;
    flex-direction: column;
    background-color: var(--secondary-text-color);
    color: var(--primary-text-color);
    font-size: 18px;
    width: 100%;
    height: 380px;
    border-radius: 25px;
    backface-visibility: hidden; /* Hide front when back is visible */
    border: 2px solid var(--primary-text-color);
    box-sizing: border-box;
}

/* On hover, rotate the entire card */
.activeCard:hover .blogCard {
    transform: rotateY(180deg); /* Rotate the whole card to show the back */
}

/* Ensure the back face appears correctly during the flip */
.activeCard:hover .blogCardBack {
    transform: rotateY(180deg); /* Back face becomes visible */
}

/* Ensure the front face hides during the flip */
.activeCard:hover .blogCardFront {
    transform: rotateY(0deg); /* Front face is hidden */
}

.blogCardBack h1 {
    font-size: 20px;
    font-weight: 500;
    padding: 10px;
    text-align: left;
    margin-bottom: -20%;
}

.blogCardBack p {
    font-size: 15px;
    font-weight: 200;
    padding: 10px;
    text-align: left;
}

@media (max-width: 768px) {
    .blogItem {
        left: 30%; /* Center horizontally */
    }

    .blogCard {
        width: 200px; /* Optional: Adjust width for mobile */
        height: 250px; /* Optional: Adjust height for mobile */
    }
    .blogCardBack {
        height: 305px;
    }

    .blogCardBack h1 {
        font-size: 18px;
    }
    
    .blogCardBack p {
        font-size: 15px;
    }
}


@media (max-width: 480px) {
    .blogItem {
        left: 30%; /* Center horizontally */
    }

    .blogCard {
        width: 200px; /* Optional: Adjust width for mobile */
        height: 250px; /* Optional: Adjust height for mobile */
    }
    .blogCardBack {
        height: 305px;
    }

    .blogCardBack h1 {
        font-size: 15px;
    }
    
    .blogCardBack p {
        font-size: 13px;
    }
}
