.social-icons-container {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1000;
    margin: 0;
    padding: 0;
    margin-top: 80vh;
    margin-left: 80px;
    cursor: pointer;
    opacity: 50%;
    height:30px;
    width: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: transform 0.4s ease, background-color 0.4s ease;
}

.social-icon-wrapper {
    position: relative;
    cursor: pointer;
    opacity: 0.8;
    transition: opacity 0.3s ease;
}

.social-icon-wrapper:hover {
    opacity: 1;
}

.tooltip {
    position: absolute;
    left: 30px; /* Position to the right of the icon */
    top: 50%;
    transform: translateY(-50%);
    background-color: var(--background-color);
    color: var(--primary-text-color);
    padding: 5px 10px;
    border-radius: 5px;
    font-size: 12px;
    white-space: nowrap;
    z-index: 1001;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    transition: opacity 0.3s ease;
}

.tooltip::before {
    content: "";
    position: absolute;
    left: -5px;
    top: 50%;
    transform: translateY(-50%);
    border-width: 5px;
    border-style: solid;
    border-color: transparent transparent transparent;
}

@media (max-width: 666px) {
    .social-icons-container {
        display: none;
    }
}
