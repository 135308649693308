.services-content {
    display: grid;
    position: sticky;
    top: 0;
    width: 100%;
    height: 100vh;
    grid-template-columns: 0.5fr 1fr 1fr 0.5fr;
    grid-template-rows: 0.5fr 1fr 0.5fr;
    align-items: center;
    gap: 145px;
    background-color: transparent;
}

.services-description {
    width: 100%;
    position: absolute;
    color: var(--primary-text-color);
    grid-area: 2 / 3 / 3 / 4;
}

.services-description h1 {
    font-size: 30px;
    text-align: left;
    font-family: "Poppins", sans-serif;
    font-weight: 500;
}

.services-description h2 {
    font-size: 18px;
    text-align: left;
    font-family: "Poppins", sans-serif;
    font-weight: 300;
}

.servicesTextIcons {
    grid-area: 2 / 2 / 3 / 3;
    display: grid;
    grid-template-rows: repeat(2, 1fr);
    grid-template-columns: repeat(2, 1fr);
    row-gap: 30px;
    justify-items: center;
}

.imageServiceBorder {
    width: 150px;
    height: 150px;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: background-color 0.5s ease, border-color 0.5s ease;
    border-radius: 25px;
    border: 2px solid var(--primary-text-color);
    background-color: transparent;
}

.imageServiceBorder:hover,
.imageServiceBorder.active {
    background-color: var(--primary-text-color);
    border: 2px solid var(--primary-text-color);
}

.servicesTextIcons svg {
    width: 50%;
    height: 50%;
    cursor: pointer;
    transition: filter 0.7s ease-in-out, transform 0.5s ease-in-out, fill 0.5s ease;
    color: var(--primary-text-color);
    /* Default SVG fill color */
    display: block;
    /* Ensures no extra inline spacing */
    margin: auto;
}

.servicesTextIcons svg:hover {
    color: var(--background-color);
    /* Blue color on hover */
    transform: scale(1.5);
    /* Slightly enlarge the SVG for a smoother effect */
    transition: all 0.3s ease-in-out;
}

.servicesTextIcons svg.active-svg {
    color: var(--background-color);
    /* Blue color when active */
    transform: scale(1.5);
    /* Slightly enlarge the SVG for a smoother effect */
    transition: all 0.3s ease-in-out;
}

.highlightServices {
    color: var(--secondary-text-color);
    font-weight: 500;
}

.learnButton {
    --color: var(--primary-text-color);
    width: max(10vw, 200px);
    max-width: 200px;
    padding: 0.8em 2em;
    background-color: transparent;
    border-radius: 30px;
    position: relative;
    overflow: hidden;
    cursor: pointer;
    transition: 0.5s;
    font-weight: 300;
    font-size: 0.75rem;
    border: 1px solid;
    font-family: "Poppins", sans-serif;
    text-transform: uppercase;
    border-color: var(--secondary-text-color);
    color: var(--color);
    z-index: 1;
}

.learnButton::before,
.learnButton::after {
    content: '';
    display: block;
    width: 35px;
    height: 35px;
    transform: translate(-50%, -50%);
    position: absolute;
    border-radius: 50%;
    z-index: -1;
    background-color: var(--secondary-text-color);
    transition: 1s ease;
}

.learnButton::before {
    top: -1em;
    left: -1em;
}

.learnButton::after {
    left: calc(100% + 1em);
    top: calc(100% + 1em);
}

.learnButton:hover::before,
.learnButton:hover::after {
    height: 410px;
    width: 410px;
}

.learnButton:hover {
    color: var(--primary-text-color);
}

.learnButton:active {
    filter: brightness(.8);
}

/* .identity-content-header {
    margin: 20px !important;
} */

.identity-content-col1 {
    min-width: 452px;
}

.identity-content-col1,
.identity-content-col2 {
    padding-left: 3% !important;
    padding-right: 3% !important;
}

/* Mobile Styles */
@media (max-width: 768px) {
    .identity-content-header {
        width: 70vw !important;
    }

    .identity-content-title {
        width: unset !important;
    }

    .identity-content-image {
        top: unset !important;
        bottom: 20px;
    }

    .identity-content-col2 {
        width: 70% !important;
        text-align: center;
    }

    /* Adjust the breakpoint as needed */
    .services-content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100vh;
        /* Full screen height */
    }

    .servicesTextIcons {
        grid-area: unset;
        display: flex;
        flex-direction: column;
        /* Stack icons vertically */
        align-items: center;
        /* Center icons */
        margin: 0;
        /* Reset margin */
    }

    .services-description {
        grid-area: unset;
        position: static;
        text-align: center;
        order: 2;
        width: 70%;
        margin-top: -20%;
    }

    .services-description h1,
    .services-description h2 {
        text-align: center;
    }

    .imageServiceBorder {
        width: 100px;
        height: 100px;
        display: none;
        /* Hide all icons by default */
    }

    .imageServiceBorder.active {
        display: flex;
        /* Show the active icon */
    }

    /* Center the selected icon above the description */
    .servicesTextIcons .active {
        display: flex;
        /* Ensure the active icon is displayed */
        justify-content: center;
        /* Center the icon */
        align-items: center;
        /* Center the icon vertically */
    }
}