.contactContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 100vh;
    position: sticky;
    top: 0;
}

.worldContainer {
    position: relative;
    width: 100%;
    max-width: 50%;
    height: auto;
}

.worldImage {
    width: 100%;
    height: auto;
    object-fit: cover;
}

.plus1, .plus2, .plus3 {
    position: absolute;
    cursor: pointer;
    font-size: 30px;
    transition: font-size 0.3s ease;
}

/* Contact information text */
.plus1Text, .plus2Text, .plus3Text {
    position: absolute;
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.3s ease, visibility 0.3s ease;
    border: none;
    border-radius: 60px;
    background-color: var(--primary-text-color);
    width: 200px;
    padding: 10px;
    z-index: 2;
    font-size: 12px;
}

.plus1:hover, .plus2:hover, .plus3:hover {
    font-size: 40px;
}

/* Show text when active */
.plus1Text.active, .plus2Text.active, .plus3Text.active {
    opacity: 1;
    visibility: visible;
}

/* USA Positioning based on percentage */
.plus1 {
    top: 35%; /* Adjusted based on 1366x768 resolution */
    left: 25%;
}

.plus1Text {
    top: 15%; /* Adjusted based on 1366x768 resolution */
    left: 0%;
}

/* Romania Positioning based on percentage */
.plus2 {
    top: 33%; /* Adjusted based on 1366x768 resolution */
    left: 53%;
}

.plus2Text {
    top: 18%;
    left: 25%;
}

/* India Positioning based on percentage */
.plus3 {
    top: 47%; /* Adjusted based on 1366x768 resolution */
    left: 67%;
}

.plus3Text {
    top: 27%;
    left: 60%;
}

/* Contact Button */
.contactButton {
    margin-top: -2%;
}

.button {
    padding: 0.8em 1.7em;
    background-color: transparent;
    border-radius: 30px;
    font-size: 12px;
    border: 1px solid var(--secondary-text-color);
    color: var(--primary-text-color);
    cursor: pointer;
    transition: 0.5s;
}

.button:hover {
    color: var(--primary-text-color);
}

/* Responsive Styles */
@media (max-width: 768px) {

    .plus1, .plus2, .plus3 {
        font-size: 20px;
    }

    .plus1Text, .plus2Text, .plus3Text {
        width: 160px;
        font-size: 10px;
    }

    .plus1 {
        top: 35%;
        left: 23%;
    }

    .plus1Text {
        top: 40%;
        left: 25%;
    }

    .plus2 {
        top: 33%;
        left: 55%;
    }

    .plus2Text {
        top:10%;
        left: 30%;
    }

    .plus3 {
        top: 42%;
        left: 67%;
    }

    .plus3Text {
        top: 17%;
        left: 60%;
    }
}

@media (max-width: 480px) {
    .plus1, .plus2, .plus3 {
        font-size: 15px;
    }

    .plus1Text, .plus2Text, .plus3Text {
        width: 140px;
        font-size: 8px;
    }

    .plus1 {
        top: 35%;
        left: 20%;
    }

    .plus1Text {
        top: 40%;
        left: 25%;
    }

    .plus2 {
        top: 33%;
        left: 55%;
    }

    .plus2Text {
        top: -5%;
        left: 40%;
    }

    .plus3 {
        top: 42%;
        left: 67%;
    }

    .plus3Text {
        top: 0%;
        left: 55%;
    }
}
