.header-tile {
    background-color: rgb(13,0,66);
    height: 100vh;
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    flex-direction: column;
    padding: 0;
    margin: 0;
  }
  
  body {
    margin: 0;
    padding: 0;
    overflow: hidden;
  }
  
  h1 {
    font-size: 50px;
    margin: 0;
    color: white;
    font-weight: 500;
    padding: 0;
    margin-top: 0;
  }
  
  .unique-margin {
    margin-bottom: 200px !important;
  }
  
  .highlight {
    color: rgb(253, 95, 13);
    font-weight: 500;
  }
  
  .font-link {
    font-family: 'Poppins', thin;
    margin: 10px 0;
    line-height: 1.1;
    padding: 0;
  }
  
  .earth-dark-gif {
    margin-bottom: 20px;
    width: 250px;
    height: 250px;
    image-rendering: crisp-edges;
  }
  
  .earth-dark-holder {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    flex-direction: column;
  }
  
  .bulb-dark-gif {
    margin-bottom: 20px;
    width: 250px;
    height: 250px;
    image-rendering: crisp-edges;
  }
  
  .bulb-dark-holder {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    flex-direction: column;
  }
  
  .lock-dark-gif {
    margin-bottom: 20px;
    width: 250px;
    height: 250px;
    image-rendering: crisp-edges;
  }
  
  .lock-dark-holder {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    flex-direction: column;
  }
  
  .medal-dark-image {
    margin: 0px;
    width: 250px;
    height: 250px;
    image-rendering: crisp-edges;
  }
  
  .medal-dark-holder {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    flex-direction: column;
  }
  
  .text-bubble-dark1-gif {
    margin: 0 15px;
    margin-bottom: 30px;
    width: 125px;
    height: 125px;
    image-rendering: crisp-edges;
  }
  
  .text-bubble-dark1-2-holder {
    display: flex;
    align-items: center;
    justify-content: center;
    height: auto;
  }
  
  .text-bubble-dark2-gif {
    margin: 0 15px;
    margin-bottom: 30px;
    width: 125px;
    height: 125px;
    image-rendering: crisp-edges;
  }
  
  .text-bubble-container {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .text-bubble-dark3-gif {
    margin: 0 15px;
    margin-bottom: 30px;
    width: 70px;
    height: 70px;
    image-rendering: crisp-edges;
  }
  
  .cyber-net-dark-gif {
    margin-bottom: 20px;
    width: 250px;
    height: 250px;
    image-rendering: crisp-edges;
  }
  
  .cyber-net-dark-holder {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    flex-direction: column;
    
  }
  
  .curved-earth-dark-svg {
    margin: 0;
    padding: 0;
    width: 900px;
    height: 800px;
    image-rendering: pixelated;
    display: block;
  }
  
  .curved-earth-dark-holder {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100vh;
    margin-bottom: 20%;
  }

  .chabababa {
    height: 350px;
    margin-top: -10%;
  }
  
  .question-mark-dark-gif {
    margin-bottom: 20px;
    width: 250px;
    height: auto;
    image-rendering: crisp-edges;
  }
  
  @media (max-width: 768px) {
    .question-mark-dark-gif {
      width: 30%;
    }
  }

  .question-mark-dark-holder {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    flex-direction: column;
  }

  .question-mark-dark-multi-gif {
    margin-bottom: 20px;
    width: 125px;
    height: 125px;
    image-rendering: crisp-edges;
  }