.SkipButton {
    --color: var(--primary-text-color);
    width: 20vw; 
    max-width: 200px;
    padding: 0.8em 2em; 
    background-color: transparent;
    border-radius: 30px;
    overflow: hidden;
    cursor: pointer;
    transition: 0.5s;
    font-weight: 300;
    font-size: 0.75rem; 
    border: 1px solid;
    font-family: "Poppins", sans-serif;
    text-transform: uppercase;
    border-color: var(--secondary-text-color);
    color: var(--color);
    position: absolute;
    top: 0;
    right: 0;
    z-index: 1000;
    margin: 0;
    margin-top: 80vh;
    margin-right: 80px;
    opacity: 50%;
}

.SkipButton::before,
.SkipButton::after {
    content: '';
    display: block;
    width: 35px;
    height: 35px;
    transform: translate(-50%, -50%);
    position: absolute;
    border-radius: 50%;
    z-index: -1;
    background-color: var(--secondary-text-color);
    transition: 1s ease;
}

.SkipButton::before {
    top: -1em;
    left: -1em;
}

.SkipButton::after {
    left: calc(100% + 1em);
    top: calc(100% + 1em);
}

.SkipButton:hover::before,
.SkipButton:hover::after {
    height: 410px;
    width: 410px;
}

.SkipButton:hover {
    color: var(--primary-text-color);
    opacity: 100%;
}

.SkipButton:active {
    filter: brightness(.8);
}