.ServicesComponent4Container {
    background-color: var(--background-color);
    position: sticky;
    top: 10%;
    height: 100vh;
    display: flex;
    flex-direction: column; /* Stack title on top of the content */
    justify-content: flex-start; /* Align title and content at the top */
    align-items: center; /* Center horizontally */
}

.ServicesComponent4Text {
    position: relative;
    top: 10%;
}

.ServicesComponent4Text h1 {
    color: var(--primary-text-color);
    font-size: 50px; /* Adjust font size as needed */
    font-weight: 500;
    text-align: center; /* Center the text */
    width: 100%; /* Full width */
}

.highlightServices4 {
    color: var(--secondary-text-color);
    font-weight: 500;
}

.services4ContentWrapper {
    border: 2px solid var(--primary-text-color);
    border-radius: 25px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 60%; /* Full width for content wrapper */
    height: 40%;
    flex-direction: row;
    margin-top: 5%; /* Space between title and content */
}

.services4ImageWrapper {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center; /* Center image wrapper horizontally */
    height: 100%;
}

.services4Image {
    width: 30%;
    height: auto; 
    object-fit: cover;
}

.services4Header {
    margin-top: 20px;
    font-size: 20px;
    font-weight: 500;
    text-align: center; /* Center header text */
    color: var(--primary-text-color);
    width: 70%; 
}

.services4TextWrapper {
    flex: 1;
    text-align: left;
}

.services4TextWrapper p {
    font-size: 18px;
    line-height: 1.5;
    color: var(--primary-text-color);
    width: 80%; /* Full width for paragraph */
}

/* Mobile responsiveness */
@media (max-width: 768px) {
    .services4ContentWrapper {
        width: 100%;
        padding: 5px;
        flex-direction: column; /* Stack content vertically */
        align-items: center; /* Center content */
        border: none;
        margin-top: 15%;
    }

    .services4ImageWrapper {
        margin-bottom: 20px; /* Space below image */
    }

    .services4Image {
        width: 30%; /* Wider image on mobile */
        height: auto; /* Maintain aspect ratio */
    }

    .services4Header {
        font-size: 25px; /* Increase font size for mobile */
        width: 50%; /* Full width */
    }

    .services4TextWrapper {
        margin-top: 0; /* Reset margin */
        text-align: center; /* Center text */
        width: 50%; /* Full width */
    }

    .services4TextWrapper p {
        font-size: 15px; /* Smaller font size */
        width: 100%; /* Full width */
    }
}

@media (max-width: 480px) {
    .services4ContentWrapper {
        width: 100%;
        padding: 5px;
        flex-direction: column; /* Stack content vertically */
        align-items: center; /* Center content */
        border: none;
        margin-top: 15%;
    }

    .ServicesComponent4Text h1 {
        font-size: 30px;
        padding-bottom: 50px;
    }

    .services4Image {
        width: 30%; /* Wider image for small screens */
        height: auto; /* Maintain aspect ratio */
    }

    .services4Header {
        font-size: 20px; /* Adjust font size */
        width: 80%; /* Full width */
    }

    .services4TextWrapper {
        text-align: center; /* Center text */
        width: 90%; /* Full width */
    }

    .services4TextWrapper p {
        font-size: 13px; /* Smaller font size */
        width: 100%; /* Full width */
    }
}
