/* 400vh scroll area */
.wayComponentScrollArea {
    height: 400vh;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
}

/* The WayComponentContainer stays in view (sticky) and takes up 100vh */
.wayComponentContainer {
    height: 100vh;
    position: sticky;
    top: 0;
    display: flex;
    flex-direction: column; /* Stack title on top of the content */
    justify-content: flex-start; /* Align title and content at the top */
    align-items: center;
    width: 100%;
}

/* Title is placed at the top of the container */
.wayComponentTitle {
    position: relative;
    top: 10%;
    color: var(--primary-text-color);
    font-size: 50px; /* Adjust font size as needed */
    font-weight: 500;
    text-align: center;
    width: 100%; /* Full width */
}

.highlightWay {
    color: var(--secondary-text-color);
    font-weight: 500;
}

.wayContentWrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 80%;
    height: 80%;
    flex-direction: row;
    margin-top: 20px; /* Space between title and content */
}

.wayImageWrapper {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.wayImage {
    width: 50%;
    height: 50%;
    object-fit: cover;
}

.wayHeader {
    margin-top: 20px;
    font-size: 30px;
    font-weight: 500;
    text-align: center;
    color: var(--primary-text-color);
    width: 50%;
}

.wayTextWrapper {
    flex: 1;
    text-align: left;
}

.wayTextWrapper p {
    font-size: 18px;
    line-height: 1.5;
    color: var(--primary-text-color);
    width: 70%;
}

/* Mobile responsiveness */
@media (max-width: 768px) {
    .wayContentWrapper {
        flex-direction: column;
        align-items: center;
    }

    .wayImageWrapper {
        margin-bottom: 20px;
    }

    .wayImage {
        width: 30%;
        height: 30%;
    }

    .wayHeader > h2{
        font-size: 25px;
        width: 100%;
    }

    .wayTextWrapper {
        margin-top: -10%;
        text-align: center;
        width: 70%;
    }

    .wayTextWrapper p {
        font-size: 14px;
        width: 100%;
    }
}

@media (max-width: 480px) {
    .wayContentWrapper {
        width: 100%;
        padding: 5px;
        flex-direction: column;
        align-items: center;
    }

    .wayComponentTitle {
        position: relative;
        top: 15%;
        color: var(--primary-text-color);
        font-weight: 500;
        text-align: center;
        width: 100%; /* Full width */
    }

    .wayComponentTitle > h1{
        font-size: 30px;
        margin-bottom: 50px;
    }

    .wayImage {
        width: 20%;
        height: 20%;
    }

    .wayHeader {
        width: 90%;
    }

    .wayHeader > h2 {
        font-size: 18px;
    }

    .wayTextWrapper {
        text-align: center;
        width: 70%;
    }

    .wayTextWrapper p {
        font-size: 13px;
        width: 100%;
    }
}
