/* Container Styling */
.sc4-solutionsComponent4Unique {
  width: 70%;
  margin: 0 auto;
  text-align: center;
  padding: 60px 20px;
  background-color: transparent;
  color: #fff;
  max-height: 90vh;
  overflow: hidden;
  box-shadow: 0px 15px 25px rgba(0, 0, 0, 0.1);
  position: relative; /* Added to ensure sticky positioning works */
}

/* Utility Class for Centering Component in Viewport */
.sc4-centerView {
  scroll-margin-top: 150px; /* Adjust this value to ensure the component centers in view properly */
}

/* Title Styling with Fade-In Animation */
.sc4-titleUnique {
  color: var(--primary-text-color);
  font-size: 60px; /* Adjusted font size for better readability */
  font-weight: 500;
  opacity: 0;
  transition: font-size 0.5s ease, transform 0.5s ease, opacity 0.5s ease;
  width: 100%;
  margin-bottom: 20px;
}

.sc4-titleUnique.showUnique {
  opacity: 1;
}

.sc4-titleUnique.shrinkUnique {
  font-size: 50px; /* Adjusted size for consistency with other components */
  transform: translateY(-10px);
}

/* Highlighted Text Styling */
.useCaseHighlight {
  color: var(--secondary-text-color);
  font-weight: 500;
}

/* Description Styling */
.sc4-descriptionUnique {
  font-size: 18px;
  line-height: 1.8;
  margin-bottom: 40px;
  color: var(--primary-text-color);
}

/* Filter Button Styling */
.sc4-filterOptionsUnique {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 15px;
  margin-bottom: 40px;
  opacity: 0;
  transition: opacity 0.5s ease;
}

.sc4-filterOptionsUnique.showUnique {
  opacity: 1;
}

.sc4-filterButtonUnique {
  background-color: rgba(255, 255, 255, 0.1);
  border: 2px solid rgba(255, 255, 255, 0.3);
  padding: 12px 25px;
  border-radius: 8px;
  cursor: pointer;
  color: var(--primary-text-color);
  transition: background-color 0.3s, transform 0.3s, box-shadow 0.3s;
  font-weight: 500;
}

.sc4-filterButtonUnique.active,
.sc4-filterButtonUnique:hover {
  background-color: var(--secondary-text-color);
  transform: translateY(-5px);
  box-shadow: 0 8px 15px rgba(0, 0, 0, 0.15);
}

/* Pulsing animation for filter buttons */
@keyframes pulseBorder {
  0% {
    border-color: rgba(255, 255, 255, 0.5);
  }
  50% {
    border-color: rgba(255, 255, 255, 1);
  }
  100% {
    border-color: rgba(255, 255, 255, 0.5);
  }
}

/* Apply pulsing animation to category buttons */
.sc4-animate-border {
  border: 2px solid rgba(255, 255, 255, 0.3);
  animation: pulseBorder 2s infinite;
}

/* Use Case Container Styling with Scroll */
.sc4-useCaseContainerUnique {
  height: calc(90vh - 250px);
  overflow-y: auto;
  padding-right: 10px;
  opacity: 0;
  transition: opacity 0.5s ease;
}

.sc4-useCaseContainerUnique.showUnique {
  opacity: 1;
}

.sc4-useCaseGridUnique {
  display: grid;
  grid-template-columns: 1fr;
  gap: 30px;
}

/* Use Case Category Styling */
.sc4-useCaseCategoryUnique {
  background-color: rgba(255, 255, 255, 0.05);
  border-radius: 12px;
  padding: 20px 25px;
  text-align: left;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  transition: background-color 0.3s, box-shadow 0.3s;
}

.sc4-useCaseCategoryUnique:hover {
  background-color: rgba(255, 255, 255, 0.1);
  box-shadow: 0 8px 15px rgba(0, 0, 0, 0.15);
}

/* Category Header Styling */
.sc4-categoryHeaderUnique {
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  margin-bottom: 15px;
  transition: transform 0.3s ease;
}

.sc4-categoryHeaderUnique:hover {
  transform: translateY(-3px);
}

.sc4-categoryIconUnique {
  font-size: 28px;
  margin-right: 15px;
  color: var(--secondary-text-color);
}

.sc4-arrowUnique {
  font-size: 30px;
  transition: transform 0.3s;
}

.sc4-arrowUnique.expanded {
  transform: rotate(180deg);
}

/* Use Case Category Items */
.sc4-categoryItemsUnique {
  max-height: 300px;
  overflow-y: auto;
  padding-right: 10px;
}

/* Individual Use Case Card Styling */
.sc4-useCaseCardUnique {
  background-color: rgba(0, 0, 0, 0.8);
  border-radius: 10px;
  padding: 20px;
  cursor: pointer;
  transition: transform 0.3s ease, background-color 0.3s ease, box-shadow 0.3s;
  margin-bottom: 10px;
}

.sc4-useCaseCardUnique:hover {
  transform: translateY(-5px);
  background-color: rgba(255, 255, 255, 0.1);
  box-shadow: 0 8px 15px rgba(0, 0, 0, 0.2);
}

.sc4-useCaseCardTitleUnique {
  font-size: 18px; /* Reduced for consistency */
  font-weight: 500;
  color: var(--primary-text-color);
  margin-bottom: 8px;
}

.sc4-useCaseCardDescriptionUnique {
  font-size: 16px; /* Adjusted for better readability */
  color: var(--secondary-text-color);
}

/* CSS for controlling icon size */
.sc4-icon {
  width: 40px; /* Adjust width to fit your dropdown */
  height: auto; /* Maintain aspect ratio */
  margin-right: 10px; /* Optional: add some space between the icon and text */
  vertical-align: middle; /* Align vertically to center with text */
}

/* Responsive Design Adjustments */
@media (max-width: 768px) {
  .sc4-titleUnique {
    font-size: 40px; /* Adjusted title size for tablets */
  }

  .sc4-useCaseGridUnique {
    grid-template-columns: 1fr;
  }

  .sc4-filterOptionsUnique {
    flex-wrap: wrap;
  }

  .sc4-useCaseCardTitleUnique {
    font-size: 16px; /* Further reduced for smaller screens */
  }
}

@media (max-width: 480px) {
  .sc4-titleUnique {
    color: var(--primary-text-color);
    font-size: 45px; /* Adjusted font size for better readability */
    font-weight: 500;
    opacity: 0;
    transition: font-size 0.5s ease, transform 0.5s ease, opacity 0.5s ease;
    width: 100%;
    margin-bottom: 20px;
  }

  .sc4-titleUnique.showUnique {
    opacity: 1;
  }

  .sc4-titleUnique.shrinkUnique {
    font-size: 35px; /* Adjusted size for consistency with other components */
    transform: translateY(-10px);
  }

  .sc4-filterOptionsUnique {
    flex-direction: column;
    gap: 10px;
  }

  .sc4-useCaseCardTitleUnique {
    font-size: 14px; /* Reduced for mobile */
  }

  .sc4-useCaseCardDescriptionUnique {
    font-size: 14px; /* Reduced for readability on mobile */
  }
}
