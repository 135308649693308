/* Overlay styling for the fullscreen modal */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.9);
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
}

/* Modal content container styling */
.modal-content {
  display: flex;
  width: 100%;
  height: 100%;
  background: #1a1a1a;
  color: #fff;
  overflow: hidden;
  position: relative;
}

/* Modal left section for icon and title */
.modal-left {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: #333;
  padding: 40px;
  width: 30%;
  height: 100%;
  box-sizing: border-box;
}

/* Icon inside the modal left section */
.modal-icon img {
  max-width: 150px;
  max-height: 150px;
  margin-bottom: 20px;
}

/* Title in the modal left section */
.modal-left h2 {
  font-size: 42px; /* Increased font size for the main title */
  text-align: center;
  color: var(
    --secondary-text-color
  ); /* Set title color to brand secondary color */
  margin: 0 0 20px 0;
}

/* Contact button */
.contact-us-button {
  background-color: var(
    --secondary-text-color
  ); /* Brand secondary color for button */
  color: #fff;
  font-size: 16px;
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.contact-us-button:hover {
  background-color: #ff8700; /* Lighter orange for hover state */
}

/* Modal right section for body content */
.modal-right {
  position: relative;
  padding: 70px 70px 70px 70px;
  width: 70%;
  height: 100%;
  overflow-y: auto;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

/* Content boundary for modal-right */
.modal-right .modal-body {
  max-width: 90%;
  margin: auto;
  padding-top: 40px;
  text-align: left; /* Align text to the left */
}

/* Main title in the modal right section */
.modal-main-title {
  font-size: 36px; /* Adjust font size as needed */
  color: var(
    --secondary-text-color
  ); /* Replace with your brand color variable */
  text-align: left; /* Ensure text is aligned to the left */
  margin-bottom: 20px; /* Add margin for spacing */
}

/* Styling for paragraph content */
.modal-paragraph {
  text-align: justify;
  line-height: 1.6;
  margin-bottom: 20px;
}

/* Styling for subheadings */
.modal-subheading {
  font-size: 24px;
  font-weight: 600;
  color: var(--secondary-text-color); /* Brand secondary color for subheading */
  margin-top: 30px;
  margin-bottom: 15px;
  text-align: left; /* Align subheadings to the left */
}

/* Styling for the unordered list inside the modal */
.modal-list {
  padding-left: 20px;
  list-style: none;
  margin-bottom: 20px;
}

.modal-list-item {
  position: relative;
  padding-left: 30px;
  margin-bottom: 10px;
  font-size: 18px;
  text-align: left; /* Align list items to the left */
}

/* Custom list bullet with checkmark */
.modal-list-item::before {
  content: '✔';
  color: var(--secondary-text-color); /* Brand secondary color for list icon */
  position: absolute;
  left: 0;
  top: 0;
}

/* Image and caption styling */
.image-container {
  text-align: center;
  margin: 30px 0;
}

.modal-image {
  max-width: 100%;
  height: auto;
  border-radius: 10px;
}

.image-caption {
  font-style: italic;
  color: #999;
  margin-top: 10px;
  text-align: center;
}

/* Modal close button for desktop, tablet, and mobile */
.modal-close,
.modal-close-left {
  background-color: #ff8700; /* Orange background */
  color: #ffffff; /* White 'X' */
  font-size: 20px; /* Adjusted size for consistency */
  width: 35px;
  height: 35px;
  border-radius: 50%; /* Make the button round */
  cursor: pointer;
  transition: background-color 0.3s ease;
}

/* Styling for scrollbar in modal */
.modal-right::-webkit-scrollbar {
  width: 8px;
}

.modal-right::-webkit-scrollbar-thumb {
  background: var(
    --secondary-text-color
  ); /* Brand secondary color for scrollbar thumb */
  border-radius: 4px;
}

/* Mobile view adjustments for max-width: 768px */
@media (max-width: 768px) {
  /* Stack the left and right sections vertically */
  .modal-content {
    flex-direction: column;
    width: 100%;
    height: 100%;
  }

  /* Modal left section adjustments for mobile view */
  .modal-left {
    align-items: center;
    justify-content: center;
    padding: 20px;
    width: 100%;
    height: auto; /* Allow dynamic height */
    text-align: center;
    margin-bottom: 10px; /* Reduced margin to adjust spacing */
  }

  /* Remove the title above the 'X' on mobile */
  .modal-left h2 {
    display: none;
  }

  /* Adjust the icon to take up the title's space */
  .modal-icon img {
    max-width: 70px;
    max-height: 70px;
    margin-bottom: 10px; /* Reduced margin for better spacing */
    margin-top: 10px; /* Reduced top margin */
  }

  /* Modal right section for body content */
  .modal-right {
    width: 100%;
    height: auto; /* Allow dynamic height */
    padding: 15px; /* Reduced padding to adjust for mobile screens */
    overflow-y: auto;
    box-sizing: border-box;
  }

  /* Main title and other elements for smaller screens */
  .modal-main-title {
    font-size: 26px; /* Slightly smaller for mobile */
    margin-bottom: 10px;
  }

  .modal-subheading {
    font-size: 18px;
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .modal-list-item,
  .modal-paragraph {
    font-size: 15px;
  }

  /* Reduce the image size within the modal content */
  .modal-image {
    max-width: 70%;
    margin: 10px auto;
  }
}

/* Extra small screen adjustments for max-width: 480px */
@media (max-width: 480px) {
  /* Stack the left and right sections vertically */
  .modal-content {
    flex-direction: column;
    width: 100%;
    height: 100%;
  }

  /* Modal left section adjustments for extra small screens */
  .modal-left {
    padding: 30px;
    height: auto; /* Make height dynamic */
    margin-bottom: 10px; /* Adjusted for better spacing */
    text-align: center;
  }

  /* Remove the title above the 'X' on extra small screens */
  .modal-left h2 {
    display: none;
  }

  /* Adjust the icon to take up the title's space */
  .modal-icon img {
    max-width: 60px;
    max-height: 60px;
    margin-top: 40%; /* Adjusted for better spacing */
    margin-bottom: 10px;
  }

  /* Modal right section for body content */
  .modal-right {
    padding: 10px; /* Reduced padding for extra small screens */
  }

  .modal-main-title {
    font-size: 22px;
    margin-top: -10%; /* Adjusted for spacing */
  }

  .modal-subheading {
    font-size: 16px;
  }

  .modal-list-item,
  .modal-paragraph {
    font-size: 14px;
  }
}
