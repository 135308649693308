.teamComponentContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    overflow: hidden;
    height: 100vh;
    position: relative;
}

.teamComponentHeader {
    position: relative;
    color: var(--primary-text-color);
    font-size: 50px;
    font-weight: 500;
    opacity: 0;
    transform: translateY(500px);
    transition: opacity 1s ease, transform 1s ease;
}

.teamComponentHeader.animate {
    opacity: 1;
    transform: translateY(0);
}

.highlightTeam {
    color: var(--secondary-text-color);
}

.teamItemWrapper {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-wrap: wrap; /* Allow wrapping */
    width: 90%; /* Adjust to 90% to improve flexibility */
    margin-top: -1%;
}

.teamItem {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    width: 20%; /* Base width */
}

.teamImage {
    width: 70%;
    height: auto;
    filter: grayscale(100%);
    transition: filter 0.5s ease;
    border-radius: 20px;
}

.teamItem:hover .teamImage {
    filter: grayscale(0%);
}

.teamContent {
    margin-top: 10px;
    text-align: center;
    color: var(--primary-text-color);
}

.teamContentHeader > h1 {
    height: 70px;
}

.teamContent a {
    display: inline-block;
    text-align: center;
}

.linkedInImage {
    width: 10%;
    height: auto;
}

.teamContent h2 {
    font-size: 17px;
    font-weight: 600;
    margin: 5px 0;
    text-align: center;
}

.teamContent p {
    font-size: 12px;
    font-weight: 400;
    margin: 0;
}

/* Mobile responsiveness */
@media (max-width: 768px) {
    .teamItem {
        width: 30%; /* Adjust to take more space */
    }
    
    .teamContentHeader > h1 {
        height: 60px;
    }

    .teamContent h2 {
        font-size: 15px;
    }

    .teamContent p {
        font-size: 12px;
    }

    .linkedInImage {
        width: 10%;
        height: auto;
    }
}

@media (max-width: 480px) {
    .teamItem {
        width: 40%; /* Increase width for smaller screens */
    }

    .teamComponentHeader > h1{
        font-size: 24px;
        margin-bottom: 20px;
    }

    .teamContent h2 {
        font-size: 14px;
    }

    .teamContentHeader {
        height: 50px;
    }

    .teamContent p {
        font-size: 8px;
    }

    .linkedInImage {
        width: 10%;
        height: auto;
    }
}
