.scroll-arrow-container {
  opacity: 0;
  position: fixed;
  bottom: 60px;
  /* Position it near the bottom */
  left: 50%;
  transform: translateX(-50%);
  z-index: 999;
  width: 18px;
  height: 30px;
  /* Length of the arrow's stem */
  border: white solid 1px;
  border-radius: 20px;
  /* Rounded edges for the stem */
  cursor: pointer;
  animation: bounce 1.5s infinite;
}

.scroll-arrow {
  position: fixed;
  bottom: 20px;
  /* Position it near the bottom */
  left: 50%;
  transform: translateX(-50%);
  z-index: 999;
  width: 1px;
  height: 6px;
  /* Length of the arrow's stem */
  background-color: rgba(255, 255, 255, 0.50);
  /* Color of the arrow stem */
  border-radius: 0px;
  /* Rounded edges for the stem */
  cursor: pointer;
  /* animation: bounce 1.5s infinite; */
}

.scroller {
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  width: 0;
  height: 0;
  /* border-left: 4px solid transparent;
    border-right: 4px solid transparent;
    border-top: 6px solid white; */
  border: 2px solid white;
  border-radius: 40%;
}

/* Bouncing animation */
@keyframes bounce {

  0%,
  100% {
    transform: translateX(-50%) translateY(0);
  }

  50% {
    transform: translateX(-50%) translateY(-10px);
  }
}

@media (max-width: 768px) {
  .scroll-arrow-container {
    visibility: hidden;
  }
}