.news1Container {
    height: 100vh;
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: center;
    align-items: center;
}

.news1Title {
    color: var(--primary-text-color);
    font-size: 70px;
    /* Default font size for larger screens */
    top: 50%;
    /* Center it vertically */
    transform: translateY(-15%);
    /* Adjust for vertical centering */
    font-weight: 500;
    transition: font-size 0.5s ease, top 0.5s ease;
    /* Smooth transition */
    text-align: center;
    width: 100%;
    /* Make sure title takes up full width on mobile */
}

.news1Title.animate {
    font-size: 50px;
    /* Smaller size after animation */
    top: 15%;
}

.highlightNews1 {
    color: var(--secondary-text-color);
    font-weight: 500;
}

.news1Paragraph {
    width: 40%;
    /* Default width for larger screens */
    line-height: 1.7;
    color: var(--primary-text-color);
    text-align: center;
    opacity: 0;
    font-size: 18px;
    filter: blur(10px);
    transition: opacity 0.5s ease-in-out, filter 0.5s ease-in-out;
}

.news1Paragraph.show {
    opacity: 1;
    filter: blur(0px);
}

.news1Paragraph.hide {
    opacity: 0;
    filter: blur(5px);
}

.newsArticle1 {
    height: 35%;
    position: relative;
    top: 33%;
}

/* Media queries for smaller screens */
@media (max-width: 768px) {
    .news1Title {
        font-size: 50px;
        /* Smaller title font size for tablets */
        top: 40%;
        /* Adjust top position */
    }

    .news1Title.animate {
        font-size: 40px;
        /* Adjust after animation for tablets */
        top: 20%;
    }

    .news1Paragraph {
        width: 60%;
        /* Increase paragraph width for smaller screens */
        font-size: 16px;
        /* Slightly smaller text */
    }
    .newsArticle1 {
        height: 40%;
        position: relative;
        top: 32%;
        left: -10%;
    }
}

@media (max-width: 480px) {
    .news1Title>h1 {
        font-size: 40px;
        /* Smaller title for mobile */
        top: 35%;
        /* Adjust top position for mobile */
    }

    .news1Title.animate>h1 {
        font-size: 30px;
        /* Even smaller after animation for mobile */
        top: 25%;
    }

    .news1Paragraph {
        width: 80%;
        /* Full width paragraph for mobile */
        font-size: 14px;
        /* Smaller text on mobile */
    }

    .newsArticle1 {
        height: 30%;
        position: relative;
        top: 35%;
        left: -12%;
    }
}