.contactContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column; /* Stack items vertically (image + button) */
    height: 100vh;
    position: sticky;
    top: 0;
}

.contactComponentHeader {
    position: relative;
    top: 5%;
    color: var(--primary-text-color);
    font-size: 50px;
    font-weight: 500;
    opacity: 0; /* Hidden initially */
    transform: translateY(500px); /* Start slightly below */
    transition: opacity 1s ease, transform 1s ease; /* Smooth transition */
    text-align: center;
}

.contactComponentHeader.animate {
    opacity: 1; /* Fade in */
    transform: translateY(0); /* Move to original position */
}

.highlightContact {
    color: var(--secondary-text-color);
}

.worldContainer {
    align-self: center;
    width: 691px;
    height: 389px;
}

.worldContainer div {
    font-size: large;
    z-index: 2;
    color: var(--secondary-text-color);
    font-size: 30px;
    font-weight: 500;
}

.plus1Text,
.plus2Text,
.plus3Text {
  position: absolute;
  opacity: 0; /* Initially hidden */
  visibility: hidden;
  transition: opacity 0.3s ease, visibility 0.3s ease;
}

.plus1:hover + .plus1Text,
.plus2:hover + .plus2Text,
.plus3:hover + .plus3Text,
.plus1Text.active,
.plus2Text.active,
.plus3Text.active {
  opacity: 1; /* Make the text visible */
  visibility: visible;
}

.plus1 {
    position: absolute;
    top: 40%;
    left: 39%;
    cursor: pointer;
    font-size: 30px; /* Initial font size */
    transition: font-size 0.3s ease; /* Smooth transition for font size change */
}

.plus1:hover {
    font-size: 40px; /* Increase font size by 10px on hover */
    transform: translateY(-2px);
}

.plus1Text {
    position: absolute;
    top: 35%; /* Align vertically with plus1 */
    left: 24%; /* Display to the left of plus1 */
    opacity: 0; /* Initially hidden */
    visibility: hidden;
    transition: opacity 0.3s ease, visibility 0.3s ease;
    border: none;
    border-radius: 60px;
    background-color: var(--primary-text-color);
    width:200px;
    padding: 10px;
    z-index: 2;
}

.plus1:hover + .plus1Text {
    opacity: 1; /* Make the text visible */
    visibility: visible;
}

.plus1Text h1 {
    text-align: center;
    margin-top: 1px;
    font-size: 12px;
    font-weight: 500;
    color: var(--secondary-text-color);
    line-height: 2px;
}

.plus1Text p {
    text-align: center;
    font-size: 12px;
    font-weight: 300;
    color: var(--background-color);
}

.plus2 {
    position: absolute;
    top: 39%;
    left: 54%;
    cursor: pointer;
    font-size: 30px; /* Initial font size */
    transition: font-size 0.3s ease; /* Smooth transition for font size change */
}

.plus2:hover {
    font-size: 40px; /* Increase font size by 10px on hover */
    transform: translateY(-2px);
}

.plus2Text {
    position: absolute;
    top: 33%; /* Align vertically with plus1 */
    left: 39%; /* Display to the left of plus1 */
    opacity: 0; /* Initially hidden */
    visibility: hidden;
    transition: opacity 0.3s ease, visibility 0.3s ease;
    border: none;
    border-radius: 60px;
    background-color: var(--primary-text-color);
    width:200px;
    padding: 10px;
    z-index: 2;
}

.plus2:hover + .plus2Text {
    opacity: 1; /* Make the text visible */
    visibility: visible;
}

.plus2Text h1 {
    text-align: center;
    margin-top: 1px;
    font-size: 12px;
    font-weight: 500;
    color: var(--secondary-text-color);
    line-height: 2px;
}

.plus2Text p {
    text-align: center;
    font-size: 12px;
    font-weight: 300;
    color: var(--background-color);
}

.plus3 {
    position: absolute;
    top: 47%;
    left: 61%;
    cursor: pointer;
    font-size: 30px; /* Initial font size */
    transition: font-size 0.3s ease; /* Smooth transition for font size change */
}

.plus3:hover {
    font-size: 40px; /* Increase font size by 10px on hover */
    transform: translateY(-2px);
}

.plus3Text {
    position: absolute;
    top: 41%; /* Align vertically with plus1 */
    left: 46%; /* Display to the left of plus1 */
    opacity: 0; /* Initially hidden */
    visibility: hidden;
    transition: opacity 0.3s ease, visibility 0.3s ease;
    border: none;
    border-radius: 60px;
    background-color: var(--primary-text-color);
    width:200px;
    padding: 10px;
    z-index: 2;
}

.plus3:hover + .plus3Text {
    opacity: 1; /* Make the text visible */
    visibility: visible;
}

.plus3Text h1 {
    text-align: center;
    margin-top: 1px;
    font-size: 12px;
    font-weight: 500;
    color: var(--secondary-text-color);
    line-height: 2px;
}

.plus3Text p {
    text-align: center;
    font-size: 10px;
    font-weight: 300;
    color: var(--background-color);
}

.worldImage {
    position: relative;
    width: 768px;
    height: 432px;
    object-fit: cover;
}

.button {
    --color: var(--primary-text-color);
    padding: 0.8em 1.7em;
    background-color: transparent;
    border-radius: 30px;
    position: relative;
    overflow: hidden;
    cursor: pointer;
    transition: .5s;
    font-weight: 300;
    font-size: 12px;
    border: 1px solid;
    font-family: inherit;
    text-transform: uppercase;
    border-color: var(--secondary-text-color);
    color: var(--color);
    z-index: 1;
}

.button::before,
.button::after {
    content: '';
    display: block;
    width: 35px;
    height: 35px;
    transform: translate(-50%, -50%);
    position: absolute;
    border-radius: 50%;
    z-index: -1;
    background-color: var(--secondary-text-color);
    transition: 1s ease;
}

.button::before {
    top: -1em;
    left: -1em;
}

.button::after {
    left: calc(100% + 1em);
    top: calc(100% + 1em);
}

.button:hover::before,
.button:hover::after {
    height: 410px;
    width: 410px;
}

.button:hover {
    color: var(--primary-text-color);
}

.button:active {
    filter: brightness(.8);
}

@media (max-width: 768px) {
    .contactComponentHeader {
        font-size: 27px;
        top: 0%;
        text-align: center;
    }
}

@media (max-width: 480px) {
    .contactComponentHeader {
        font-size: 24px;
        top: -5%;
    }
}