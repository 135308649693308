:root {
    --sphere-color: white;
    --sphere-opacity: 0.5;
    --circle-color: #8680a1;
    --circle-opacity: 1;
}

body {
    margin: 0; /* Reset margin to prevent scrolling issues */
    overflow-x: hidden; /* Prevent horizontal scrolling on body */
}

.thinkingSphereContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    width: 100vw; /* Ensure full width */
    overflow: hidden; /* Prevent any overflow */
    position: sticky;
    top: 0;
}

.refContainer {
    position: absolute;
    top: 15%;
    width: 20vw;
    height: 20vw;
    max-width: 350px;
    max-height: 350px;
    min-width: 200px;
    min-height: 200px;
}

.title-and-sphere-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.title-wrapper {
    margin-top: 5vh; /* Ensure there’s some spacing between the sphere and text */
    font-size: 3vw;
    font-weight: 500;
    color: var(--primary-text-color);
    text-align: center;
}

@media (min-width: 900px) {
    .refContainer {
        width: 20vw;  /* Adjust size for tablets */
        height: 20vw;
        max-width: 500px;
        max-height: 500px;
        top: 15%;
    }
    .title-wrapper {
        font-size: 3vw;  /* Adjust font size for tablets */
    }
}

@media (max-width: 768px) {
    .thinkingSphereContainer {
        width: 100vw; /* Set width to 100vw for mobile */
        overflow-x: hidden; /* Prevent horizontal overflow */
    }
    .refContainer {
        width: 40vw;  /* Adjust size for tablets */
        height: 40vw;
        max-width: 300px;
        max-height: 300px;
        top: 15%;
    }

    .title-wrapper {
        font-size: 6vw;  /* Adjust font size for tablets */
    }
}

@media (max-width: 480px) {
    .thinkingSphereContainer {
        width: 100vw; /* Ensure full width on mobile */
        overflow-x: hidden; /* Prevent horizontal overflow */
    }
    .refContainer {
        width: 50vw;  /* Adjust size for small mobile screens */
        height: 50vw;
        max-width: 250px;
        max-height: 250px;
        top: 20%;
    }

    .title-wrapper {
        font-size: 7vw;  /* Adjust font size for small screens */
    }
}
