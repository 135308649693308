.testimonialsContainer {
    display: grid;
    /* Use grid layout */
    position: sticky;
    top: 0;
    width: 100%;
    height: 100vh;
    /* Full height of the viewport */
    background-color: transparent;
    box-sizing: border-box;
    grid-template-rows: auto;
    /* Adjust rows as necessary */
    grid-template-columns: 1fr 1fr;
    /* Two equal columns */
}

/* Styles for desktop (default) */
.leftTestimonialsContainer {
    display: flex;
    flex-direction: column;
    /* Stack items vertically */
    justify-content: center;
    /* Center content vertically */
    align-items: flex-start;
    /* Align to the left */
    box-sizing: border-box;
    height: 100%;
    /* Full height of the container */
    margin: 0 50px;
    /* Margin around the container */
    padding-right: 5%;
    /* Adjust padding as necessary */
    grid-column: 1;
    /* Make it occupy the first column */
}

.leftTestimonialsContainer>.testimonialShape {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    /* Align items to the right */
    align-items: center;
    /* Center items vertically */
    position: relative;
}

.rightTestimonialContainer {
    position: relative;
    display: flex;
    flex-direction: column;
    /* Stack items vertically */
    justify-content: center;
    /* Center vertically */
    align-items: flex-start;
    /* Align to the left */
    box-sizing: border-box;
    height: 100%;
    /* Full height of the container */
    padding-left: 0;
    /* Adjust padding as necessary */
    margin: 5% 50px;
    /* Margin for the right container */
    grid-column: 2;
    /* Make it occupy the second column */
}

.rightTestimonialText {
    color: var(--primary-text-color);
    font-size: 18px;
    line-height: 1.2;
    transition: opacity 0.5s ease-in-out;
    text-align: left;
    /* Keep text aligned to the left */
    width: 70%;
    /* Adjust as necessary */
    font-weight: 200;
}

.testimonialHeaderText {
    font-size: 30px;
    font-weight: 500;
}

.testimonialSmallText {
    font-size: 15px;
    font-weight: 200;
}

.boldHeaderText {
    font-weight: 500;
    color: var(--secondary-text-color);
    font-style: normal;
}

.testimonialShape {
    border-radius: 20px;
    margin: 50px 0;
    /* Vertical margin */
    position: relative;
}

.selectedTestimonial {
    opacity: 1;
}

.testimonialHidden {
    opacity: 0.5;
    filter: grayscale(100%);
}

.testimonialTransition {
    transition: all 0.5s ease-in-out;
}

.hiddenTestimonialTitle {
    position: absolute;
    font-size: 18px;
    color: var(--primary-text-color);
    transition: all 0.5s ease-in-out;
    text-align: right;
    line-height: 0.75;
    font-weight: 500;
    top: 50%;
}

.highlightTestimonial {
    color: var(--secondary-text-color);
    font-weight: 500;
}

.visible {
    opacity: 1;
}

.hidden {
    opacity: 0;
}

.testimonialImageWrapper {
    display: flex;
    /* Use flex layout */
    align-items: center;
    /* Align image and arrow vertically */
    justify-content: center;
    /* Center items horizontally */
    position: relative;
    /* This allows the arrow to be positioned relative to the image */
}

.testimonialImage {
    width: 75px;
    height: 75px;
    padding-right: 50%;
}

.arrowImage {
    width: 30px;
    height: 30px;
}

/* Mobile Styles */
@media (max-width: 768px) {
    .testimonialsContainer {
        grid-template-columns: 1fr !important;
    }

    .leftTestimonialsContainer {
        display: none;
    }

    .rightTestimonialText {
        text-align: center !important;
        width: 100% !important;
    }

    .arrowImage {
        visibility: hidden;
        /* Hide the arrow on mobile */
        margin-top: 10px;
        /* Space between image and arrow */
        transform: rotate(90deg);
        /* Rotate the arrow to face down */
    }
}

@media (max-width: 0px) {
    .testimonialsContainer {
        display: flex;
        /* Switch back to flex for mobile */
        flex-direction: column;
        /* Stack left and right testimonials vertically */
        height: auto;
        /* Allow height to adjust based on content */
        justify-content: center;
        /* Center content vertically */
        align-items: center;
        /* Center content horizontally */
        padding: 0 20px;
        /* Add some horizontal padding if needed */
    }

    .leftTestimonialsContainer,
    .rightTestimonialContainer {
        width: 100%;
        /* Full width for mobile */
        margin: 0;
        /* Remove side margins */
        padding: 0;
        /* Remove padding */
        display: flex;
        /* Ensure these containers are flex containers */
        justify-content: center;
        /* Center content horizontally */
        align-items: center;
        /* Center content vertically */
    }

    .leftTestimonialsContainer {
        position: relative;
        margin-left: -70%;
        margin-top: -100%;
        /* Move up to center it further if needed */
    }

    .rightTestimonialContainer {
        padding-top: 30vh;
        /* Adjust if you want it to be lower */
    }

    .testimonialImageWrapper {
        display: flex;
        /* Use flex layout for images in mobile */
        flex-direction: row;
        /* Align images side by side */
        justify-content: space-around;
        /* Space images evenly */
        width: 100%;
        /* Ensure full width */
        align-items: center;
        /* Center items vertically */
        margin-top: 10px;
        /* Optional: add some margin at the top */
    }

    .testimonialImage {
        width: 75px;
        /* Fixed width for images */
        height: 75px;
        /* Fixed height for images */
        padding-right: 0;
        visibility: hidden;
    }

    .arrowImage {
        visibility: hidden;
        /* Hide the arrow on mobile */
        margin-top: 10px;
        /* Space between image and arrow */
        transform: rotate(90deg);
        /* Rotate the arrow to face down */
    }

    /* Optional: Adjust testimonial text for mobile */
    .rightTestimonialText {
        width: 80%;
        /* Adjust text width for better visibility */
        text-align: center;
        /* Center the text */
    }
}