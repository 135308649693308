:root {
  /* Define variables for both light and dark modes */
  --primary-text-color: #ffffff; /* White text color */
  --secondary-text-color: #ff5500; /* Orange accent color */
  --dark-bg-color: #0e0e2c; /* Dark blue background color */
}

@media (prefers-color-scheme: light) {
  /* Set the same variable values as the default */
  :root {
    --primary-text-color: #0e0e2c;
    --secondary-text-color: #ff5500;
    --primary-bg-color: #ffffff;
  }
}

@media (prefers-color-scheme: dark) {
  /* Set the same variable values as the default */
  :root {
    --primary-text-color: #ffffff;
    --secondary-text-color: #ff5500;
    --dark-bg-color: #0e0e2c;
  }
}
/* Sticky container for scroll behavior */
.sc1_container {
  height: 100vh;
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: center;
  position: sticky;
  top: 0;
  z-index: 10;
  overflow: visible;
  scroll-snap-align: start; /* Ensures snap at the start of the container */
  scroll-padding-top: 80px; /* Adjust to match the navbar height */
}

/* Title Alignment */
.sc1_title {
  color: var(--primary-text-color);
  font-weight: 500;
  text-align: center;
  width: 100%;
  opacity: 0;
  transition: font-size 0.5s ease, transform 0.5s ease, opacity 0.5s ease;
  margin-bottom: 20px;
}

.sc1_title.show {
  opacity: 1;
}

.sc1_title.shrink {
  transform: translateY(-10px);
}

.sc1_title h2 {
  font-size: 60px;
  font-weight: 500;
}

.sc1_title.shrink h2 {
  font-size: 40px;
  font-weight: 500;
}

/* Highlight for Orange Text in Title */
.sc1_highlight {
  color: var(--secondary-text-color);
  font-weight: 500;
}

/* Paragraph container width adjustment */
.sc1_paragraph {
  width: 50%;
  line-height: 1.5;
  color: var(--primary-text-color);
  text-align: center;
  opacity: 0;
  transition: opacity 0.5s ease, transform 0.5s ease;
}

.sc1_paragraph.show {
  opacity: 1;
  transform: translateY(0);
}

.sc1_paragraph.show p {
  font-size: 18px;
  font-weight: 300;
}

/* Solution Grid Section */
.sc1_grid {
  display: flex;
  justify-content: space-around;
  align-items: stretch;
  gap: 30px;
  max-width: 1000px;
  margin: 20px auto;
  padding: 20px;
  flex-wrap: wrap;
  opacity: 0;
  transform: translateY(20px);
  transition: opacity 0.5s ease, transform 0.5s ease;
}

.sc1_grid.show {
  opacity: 1;
  transform: translateY(0);
}

/* Individual Solution Item Styling */
.sc1_item {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 210px;
  min-height: 240px;
  cursor: pointer;
  padding: 10px;
  border-radius: 15px;
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.2);
  background: white;
  color: rgb(13, 0, 66);
  transition: transform 0.3s ease, background 0.4s ease, box-shadow 0.4s ease;
}

/* Hover Effect with Predominantly Blue and Subtle Orange Gradient */
.sc1_item:hover {
  transform: translateY(-10px);
  background: linear-gradient(
    145deg,
    rgba(0, 0, 139, 0.95),
    rgba(255, 85, 0, 0.15)
  );
  box-shadow: 0 15px 30px rgba(0, 0, 0, 0.3);
}

/* Image Styling */
.sc1_item-img {
  width: 100px;
  height: 100px;
  margin-bottom: 5px;
  transition: transform 0.3s ease-in-out;
}

.sc1_item:hover .sc1_item-img {
  transform: rotate(10deg) scale(1.05);
}

/* Text Styling for Each Solution */
.sc1_item-text {
  font-size: 18px;
  font-weight: 600;
  color: var(--secondary-text-color);
  text-transform: uppercase;
  letter-spacing: 1px;
  margin-bottom: 4px;
  transition: color 0.3s ease;
}

/* Solution Item Description */
.sc1_item-description {
  font-size: 14px;
  color: var(--primary-text-color);
  text-align: center;
  margin: 5px 0;
}

/* Learn More Button Styling */
.sc1_learnMoreButton {
  background-color: var(--secondary-text-color);
  color: var(--primary-text-color);
  border: none;
  padding: 6px 12px;
  border-radius: 4px;
  cursor: pointer;
  text-decoration: none;
  font-weight: 500;
  margin-top: 5px;
  transition: outline 0.3s ease, background-color 0.3s ease;
}

.sc1_learnMoreButton:hover {
  outline: 3px solid var(--primary-text-color);
  background-color: var(--primary-text-color);
  color: var(--secondary-text-color);
}

/* Media queries for smaller screens */
@media (max-width: 768px) {
  .sc1_title {
    font-size: 40px; /* Adjusted for smaller screens */
    margin-top: 50px;
  }

  .sc1_paragraph {
    width: 80%;
    font-size: 16px;
  }

  .sc1_grid {
    display: flex;
    flex-direction: row; /* Arrange items in a horizontal row */
    gap: 15px; /* Space between each card */
    overflow-x: scroll; /* Enable horizontal scrolling */
    white-space: nowrap; /* Prevent wrapping of items */
    scroll-snap-type: x mandatory; /* Make scrolling snap to each card */
    padding: 20px 10px;
    width: 100%;
    box-sizing: border-box; /* Ensure padding doesn't add to total width */
    -webkit-overflow-scrolling: touch; /* Smooth scrolling for mobile devices */
  }

  .sc1_item {
    flex: 0 0 auto; /* Ensure cards maintain their width */
    width: 250px; /* Set consistent width for cards */
    min-height: 280px;
    margin-right: 10px; /* Space between items */
    scroll-snap-align: center; /* Align cards centrally during scroll */
    display: inline-flex; /* Make cards inline */
    align-items: center;
    flex-direction: column; /* Keep internal elements in a column layout */
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.2);
    background: white;
    color: rgb(13, 0, 66);
    transition: transform 0.3s ease, background 0.4s ease, box-shadow 0.4s ease;
  }

  .sc1_item-description {
    font-size: 13px;
    white-space: normal; /* Prevent text overflow */
    overflow: hidden; /* Ensure no overflow of text */
    text-overflow: ellipsis; /* Add ellipsis for overflow */
  }

  .sc1_learnMoreButton {
    padding: 5px 10px;
  }
}

@media (max-width: 480px) {
  .sc1_title {
    color: var(--primary-text-color);
    font-size: 40px;
    font-weight: 500;
    text-align: center;
    width: 100%;
    opacity: 0;
    transition: font-size 0.5s ease, transform 0.5s ease, opacity 0.5s ease;
    margin-top: 100px;
  }

  .sc1_title.show {
    opacity: 1;
  }

  .sc1_title.shrink {
    transform: translateY(-50px);
  }
  .sc1_title.shrink h2 {
    font-size: 22px;
    font-weight: 500;
  }
  .sc1_paragraph {
    width: 90%;
    font-size: 12px;
    margin-top: -50px;
  }

  .sc1_paragraph.show p {
    font-size: 12px;
    font-weight: 300;
  }

  .sc1_grid {
    display: flex;
    flex-direction: row; /* Arrange items in a horizontal row */
    gap: 15px; /* Space between each card */
    overflow-x: scroll; /* Enable horizontal scrolling */
    white-space: nowrap; /* Prevent wrapping of items */
    scroll-snap-type: x mandatory; /* Make scrolling snap to each card */
    padding: 15px 10px;
    width: 100%;
    box-sizing: border-box; /* Ensure padding doesn't add to total width */
    -webkit-overflow-scrolling: touch; /* Smooth scrolling for mobile devices */
  }

  .sc1_item {
    flex: 0 0 auto; /* Ensure cards maintain their width */
    width: 180px; /* Reduce width for smaller screens */
    max-width: 250px;
    min-height: auto;
    margin-right: 10px; /* Space between items */
    scroll-snap-align: center; /* Align cards centrally during scroll */
    display: inline-flex; /* Make cards inline */
    align-items: center;
    flex-direction: column; /* Keep internal elements in a column layout */
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.2);
    background: white;
    color: rgb(13, 0, 66);
    transition: transform 0.3s ease, background 0.4s ease, box-shadow 0.4s ease;
  }

  .sc1_item-description {
    font-size: 12px;
    white-space: normal; /* Prevent text overflow */
    overflow: hidden; /* Ensure no overflow of text */
    text-overflow: ellipsis; /* Add ellipsis for overflow */
  }

  .sc1_learnMoreButton {
    padding: 4px 8px;
  }
}
