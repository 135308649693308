.recognitionContainer {
    display: flex;
    /* Use flexbox to center contents */
    position: sticky;
    top: 0;
    width: 100%;
    height: 100vh;
    background-color: transparent;
}

.leftRecognitionContainer,
.rightRecognitionContainer {
    flex: 1;
    /* Each container takes up 50% of the width */
    display: flex;
    flex-direction: column;
    justify-content: center;
    /* Center content vertically */
    align-items: center;
    /* Center content horizontally */
    box-sizing: border-box;
    position: relative;
}

.leftRecognitionContainer {
    margin: 0;
    /* Remove margin to fit exactly within the container */
    top: -10%;
}

.rightRecognitionContainer {
    margin: 0;
    /* Remove margin to fit exactly within the container */
    top: 0%;
    width: 40%;
    padding-left: 0px;
}

.rightRecogntionText {
    color: var(--primary-text-color);
    font-size: 18px;
    font-weight: 300;
    line-height: 1.5;
    transition: opacity 0.5s ease-in-out;
    text-align: left;
    /* Center text horizontally */
    width: 50%;
    /* Adjust width as necessary */
    margin-left: -20px;
    /*Adjust to make text move left or right */
    margin-right: 30%;
    /*Adjust to make text move left or right */
}

.recognitionShape {
    background-color: var(--primary-text-color);
    border-radius: 20px;
    margin: 40px 0;
    position: relative;
    color: var(--secondary-text-color);
}

.selectedRecognition {
    opacity: 1;
}

.recognitionHidden {
    opacity: 0.5;
    filter: grayscale(100%);
}

.recognitionTransition {
    /* transition: all 0.5s ease-in-out; */
}

.hiddenRecognitionTitle {
    position: absolute;
}

.highlightRecognition {
    color: var(--secondary-text-color);
}

.recognitionHeader {
    font-size: 30px;
    font-weight: 500;
    text-align: center;
}

.hiddenRecognitionTitle {
    /* top: 50%; */
}

.visible {
    opacity: 1;
}

.hidden {
    opacity: 0;
}

.recognitionImageWrapper {
    display: flex;
    align-items: center;
    /* Align image and arrow vertically */
    justify-content: center;
    /* Align image and arrow horizontally */
    position: relative;
    /* This allows the arrow to be positioned relative to the image */
}

.recognitionImage {
    filter: invert(1) brightness(100);
    margin: 0;
    border: none;
    padding: 0;
    display: block;
    /* Ensures no extra inline spacing */
    width: max-content;
}

.recognitionImage1 {
    width: max-content;
}

.arrowImage {
    position: absolute;
    /* Positions the arrow on top of the image */
    right: -20px;
    /* Aligns the arrow to the right */
    top: 50%;
    /* Vertically centers the arrow */
    transform: translateY(-50%);
    /* Adjusts for perfect centering */
    width: 50px;
    /* Adjust the size as needed */
    height: auto;
    z-index: 1;
    /* Ensures the arrow is layered above the image */
    transition: opacity 0.5s ease, transform 0.5s ease;
    /* Smooth transition for showing/hiding */
}

.showArrow {
    opacity: 1;
    /* Arrow is visible */
    transform: translateY(-50%) scale(1.5);
    /* Arrow is at full size */
}

.hideArrow {
    opacity: 0;
    /* Arrow is hidden */
    transform: translateY(-50%) scale(0.8);
    /* Optional scale down when hidden */
}

/* Additional styles for mobile responsiveness */
@media (max-width: 768px) {

    /* Adjust the breakpoint as needed */
    .recognitionContainer {
        flex-direction: column;
        align-items: center;
        justify-content: center;
        /* Stack containers vertically on mobile */
    }

    .recognitionHidden {
        opacity: 0;
        /* Hide the image */
        pointer-events: none;
        /* Prevent interactions */
        /* transition: opacity 0.5s ease; */
        /* Smooth transition */
    }

    .leftRecognitionContainer,
    .rightRecognitionContainer {
        flex: none;
        /* Remove flex property to avoid height issues */
        width: 100%;
        /* Ensure full width for both containers */
    }

    .leftRecognitionContainer {
        width: 70%;
        top: unset;
        /* margin-left: -40%; */
    }

    .selectedRecognition {
        position: absolute;
        display: flex;
        justify-content: center;
        align-self: center;
    }

    .rightRecognitionContainer {
        /* top: 10%; */
    }

    .rightRecogntionText {
        width: 70%;
        /* Adjust width for better readability on mobile */
        text-align: center;
        font-size: 15px;
        margin-left: unset !important;
        margin-right: unset !important;
    }

    .recognitionHeader {
        font-size: 24px;
        font-weight: 500;
        text-align: center;
    }
}

@media (max-width: 480px) {

    /* Adjust the breakpoint as needed */
    .leftRecognitionContainer {
        /* margin-bottom: 20px; */
        /* Add spacing between containers */
        /* top: 0%; */
    }

    .rightRecogntionText {
        width: 80%;
        /* Adjust width for better readability on mobile */
        /* margin: 0px 40px; */
        text-align: center;
        font-size: 15px;
    }

    .recognitionHeader {
        font-size: 20px;
        font-weight: 500;
        text-align: center;
    }
}